import AppHeader from '../AppHeader';
import { useSelector } from 'react-redux';

import { useAppLayoutStyles } from './AppLayout.styles';
import Logo from 'images/acc.png';
import AppRoutes from './AppRoutes';
import 'simplebar/src/simplebar.css';
import PageWrapper from 'components/PageWrapper/PageWrapper';

const AppLayout = () => {
  const appThemeState = useSelector((state: any) => state.appTheme);

  const {
    root,
    headerBar,
    viewportContainer,
    rightSideContainer,
    centerViewWrapper,
    navs,
    simpleBarHeight,
  } = useAppLayoutStyles(appThemeState);
  return (
    <PageWrapper
      root={root}
      headerBar={headerBar}
      navs={navs}
      viewportContainer={viewportContainer}
      rightSideContainer={rightSideContainer}
      centerViewWrapper={centerViewWrapper}
      simpleBarHeight={simpleBarHeight}
    >
      <AppRoutes />
    </PageWrapper>
  );
};

export default AppLayout;
