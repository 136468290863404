import { red } from '@mui/material/colors';
import AllocationCell from 'components/AllocationCell';
import CustomHeader from 'components/CustomHeader';
import { useQuery } from 'react-query';
import { putTestData } from 'services/apiCalls';
import useGetDistinctFeatures from './useGetDistinctFeatures';

export default function useGetTableData(
  class_list: string[],
  start_year: string,
  start_month: string,
  end_year: string = '',
  end_month: string = '',
  store_list: string[] = [],
  dept_list: string[] = [],
  sub_category_list: string[] = [],
  flag: boolean = false
) {
  const path = `first_allocation/tabelView`;

  const { distinctRows } = useGetDistinctFeatures(
    class_list,
    start_year,
    start_month,
    end_year,
    end_month,
    store_list,
    dept_list,
    sub_category_list,
    flag
  );
  const { data, isLoading } = useQuery(
    [
      'tabelView',
      class_list,
      start_year,
      start_month,
      end_year,
      end_month,
      store_list,
      dept_list,
      sub_category_list,
      flag,
    ],
    () =>
      putTestData(path, {
        class_list,
        start_year,
        start_month,
        end_year,
        end_month,
        store_list,
        dept_list,
        sub_category_list,
        flag,
      }),
    {
      enabled:
        !!start_year &&
        !!start_month &&
        !!class_list.length &&
        Array.isArray(store_list) &&
        !!store_list.length,
    }
  );

  const columnDefs: any = [
    {
      headerName: 'Hierarchy Details',
      children: [
        {
          headerName: 'Department',
          field: 'MD_DESC',
          pinned: 'left',
          width: 150,
          headerTooltip: 'Department',
          tooltipField: 'MD_DESC',
        },
        {
          headerName: 'Sub-Category',
          field: 'SC_DESC',
          pinned: 'left',
          headerTooltip: 'Sub-Category',
          tooltipField: 'SC_DESC',
          width: 180,
        },
        {
          headerName: 'Class Description',
          field: 'CL_DESC',
          pinned: 'left',
          headerTooltip: 'Class Description',
          tooltipField: 'CL_DESC',
        },
        { headerName: 'Store', field: 'STORE_ID', pinned: 'left', hide: !flag, width: 120 },
        { headerName: 'Region', field: 'RG_DESC', pinned: 'left', hide: flag, width: 120 },
      ],
    },
  ];
  const tableRows: any = [];
  const months: any = [];
  if (Array.isArray(data) && Array.isArray(distinctRows) && distinctRows.length && data.length) {
    distinctRows.forEach((item: any) => {
      let row: any = {};
      data.forEach((dataItem: any) => {
        if (
          item.CL_ID === dataItem.CL_ID &&
          item.CL_DESC === dataItem.CL_DESC &&
          item.MD_DESC === dataItem.MD_DESC &&
          item.SC_DESC === dataItem.SC_DESC &&
          item.RG_DESC === dataItem.RG_DESC
        ) {
          row = {
            ...item,
            ...row,
            [`${dataItem.month_year}--Forecast_Sales`]: dataItem.Forecast_Sales,
            [`${dataItem.month_year}--Total_Allocated_Inventory`]:
              dataItem.Total_Allocated_Inventory,
            [`${dataItem.month_year}--Adjusted_Allocated_Inventory`]:
              dataItem.Adjusted_Allocated_Inventory,
            [`${dataItem.month_year}--deviation`]: flag
              ? dataItem.per_Allocation_Deviation
              : dataItem.Average_per_Allocation_Deviation,
            [`${dataItem.month_year}--Forecast_Sales`]: dataItem.Forecast_Sales,
          };
          if (!months.includes(dataItem.month_year)) {
            months.push(dataItem.month_year);
          }
        }
      });
      tableRows.push(row);
    });
  }
  if (Array.isArray(months) && months.length) {
    months.forEach((month: any) => {
      columnDefs.push({
        headerName: month,

        children: [
          {
            field: `${month}--Forecast_Sales`,
            headerName: 'Forecast Sales',
            minWidth: 150,
          },
          {
            field: `${month}--Total_Allocated_Inventory`,
            headerName: 'Allocation',
            cellRendererFramework: AllocationCell,
            minWidth: 150,
            cellStyle: (params: any) => {
              const colId = params?.column?.getColId();
              const monthYear = colId?.split('--')[0];
              const deviation = params.data[`${monthYear}--deviation`];
              if (deviation <= -50) {
                return { backgroundColor: red['A100'] };
              }
            },
          },
          {
            field: `${month}--Adjusted_Allocated_Inventory`,
            headerName: 'Adjustment',
            headerComponent: CustomHeader,
            headerComponentParams: { editable: true },
            minWidth: 150,
            editable: (params: any) => {
              const colId = params?.column?.getColId();
              const monthYear = colId?.split('--')[0];
              const value = params.data[`${monthYear}--Total_Allocated_Inventory`];
              return value !== undefined && value !== null && value !== '' && flag;
            },
            cellStyle: { borderRight: '1px solid #ccc' },
          },
        ],
      });
    });
  }
  return { columnDefs, tableRows, isLoading };
}
