import EditConfidenceCell from 'views/ConfigurationWrapper/ForecastConfigWrapper/Components/EditConfidenceCell';
import DeployConfidenceCell from 'views/ConfigurationWrapper/ForecastConfigWrapper/Components/DeployConfidenceCell';
import ModuleConfidenceCell from 'views/CustomizationModuleWrapper/Components/ModuleConfidenceCell';
import AppThemeConfidenceCell from 'views/ConfigurationWrapper/AppThemeConfigWrapper/Components/AppThemeConfidenceCell/AppThemeConfidenceCell';
import { forecastConfigStatus } from 'constants/forecastConfigData';
import { defaultTheme } from 'constants/defaultAppTheme';

const validateConfigData = (config: any) => {
  let validConfig = JSON.parse(config.replace(/'/g, '"'));

  return validConfig;
};
export const formatReports = (reports: any) => {
  try {
    let formattedReports: any = [];
    if (reports && reports?.length) {
      reports.forEach((report: any) => {
        let reportConfig = validateConfigData(report?.Json_Config);
        let reportLocObj = {
          reportName: report?.['Report_Name'],
          moduleTag: reportConfig?.moduleTag,
          layoutType: parseInt(reportConfig?.layoutType),
          customLayout: {
            ...reportConfig?.customLayout,
            chartsCount: parseInt(reportConfig?.customLayout?.chartsCount),
          },
          charts: reportConfig?.charts,
          infocards: reportConfig?.infocards,
        };
        formattedReports.push(reportLocObj);
      });
    }

    return formattedReports;
  } catch (e) {
    console.log('Error in formatReports:', e);
    return null;
  }
};

export const formatDate = (date: any) => {
  let formattedDate = date;
  function joinDate(t: any, formatArr: any, separtor: string) {
    function format(m: any) {
      let f = new Intl.DateTimeFormat('en', m);
      return f.format(t);
    }
    return formatArr.map(format).join(separtor);
  }
  if (date && date !== null && date !== undefined && typeof date !== 'string') {
    let format = [{ day: '2-digit' }, { month: '2-digit' }, { year: 'numeric' }];
    formattedDate = joinDate(date, format, '/');
  }

  return formattedDate;
};

export const sortData = (list: any, order: any, key: any) => {
  let sortedList: any = [];
  const ascComparator = (a: any, b: any) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  };
  const descComparator = (a: any, b: any) => {
    if (a[key] > b[key]) return -1;
    if (a[key] < b[key]) return 1;
    return 0;
  };
  if (list && list.length) {
    sortedList = [...list];
    if (order === 'asc') {
      sortedList.sort(ascComparator);
    }
    if (order === 'desc') {
      sortedList.sort(descComparator);
    }
  }
  return sortedList;
};
export const sortChartDataHandler = (data: any, orderBy: any, chartDataOrderBy: any) => {
  let sortedData: any[] = [];

  if (data && orderBy) {
    let order: string =
      chartDataOrderBy.filter((item: any) => item?.type === parseInt(orderBy?.order))[0]?.order ||
      'asc';
    let fieldKey = orderBy?.aggType + '_' + orderBy?.field;
    sortedData = sortData(data, order, fieldKey);
    return sortedData;
  } else {
    return data;
  }
};
export const formatChartData = (data: any) => {
  const formatTypes = ['date', 'date_'];
  function formatList(callback: any, key: string) {
    data.forEach((record: any) => {
      record[key] =
        record[key] && typeof record[key] !== 'string' ? callback(record[key]) : record[key];
    });
  }
  function formatWithKey(key: any) {
    switch (key) {
      case 'date':
        formatList(formatDate, key);
        break;
      case 'date_':
        formatList(formatDate, key);
        break;
      default:
        break;
    }
  }
  if (data && data?.length) {
    let keys = Object.keys(data[0]);
    keys.forEach((key: any) => {
      let index = formatTypes.indexOf(key);
      if (index !== -1) {
        formatWithKey(formatTypes[index]);
      }
    });
  }
  return data;
};
export const formatForecastConfigs = (configs: any) => {
  try {
    let columnDefs: any[] = [
      {
        headerName: 'Forecast Name',
        field: 'forecast_name',
        width: 150,
        headerTooltip: 'Forecast Name',
      },

      {
        headerName: 'Product Grain',
        field: 'prod_grain',
        width: 150,
        headerTooltip: 'Product_Grain',
      },
      {
        headerName: 'Region Grain',
        field: 'location_grain',
        width: 150,
        headerTooltip: 'Region_Grain',
      },
      {
        headerName: 'Time Grain',
        field: 'time_grain',
        width: 150,
        headerTooltip: 'Time_Grain',
      },
      {
        headerName: 'Horizon',
        field: 'horizon',
        width: 150,
        headerTooltip: 'horizon',
      },
      {
        headerName: 'Skip Period',
        field: 'skip_period',
        width: 150,
        headerTooltip: 'skip_period',
      },

      {
        headerName: 'Edit',
        field: 'edit_forecast_config',
        headerTooltip: 'Edit Forecast Config Form Values',
        cellRendererFramework: EditConfidenceCell,
        hide: false,
      },
      {
        headerName: 'Delete',
        field: 'delete_forecast_config',
        headerTooltip: 'Deletes a Forecast Configuration',
        cellRendererFramework: EditConfidenceCell,
        hide: false,
      },
      {
        headerName: 'Status',
        field: 'status_forecast_config',
        headerTooltip: 'This is the configuration generation status values',
        width: 150,
      },
      {
        headerName: 'Generate Forecast',
        field: 'generate_forecast_config',
        headerTooltip: 'Click generate button against any configuration',
        cellRendererFramework: DeployConfidenceCell,
        width: 150,
      },
      {
        headerName: 'Deploy',
        field: 'deploy_forecast_config',
        headerTooltip: 'Deploy the configuration',
        cellRendererFramework: DeployConfidenceCell,
        hide: false,
      },
    ];
    let tableRows: any[] = [];

    if (configs && configs?.length) {
      configs.forEach((config: any) => {
        if (config && config?.forecast_name) {
          let rowObject = {
            forecast_name: config?.forecast_name,
            prod_grain: config?.prod_grain,
            location_grain: config?.location_grain,
            time_grain: config?.time_grain,
            horizon: config?.horizon,
            skip_period: config?.skip_period,
            edit_forecast_config: { name: config?.forecast_name, type: 'edit' },
            delete_forecast_config: { name: config?.forecast_name, type: 'delete' },
            status_forecast_config: forecastConfigStatus?.['new'],
            generate_forecast_config: {
              status: 'new',
              name: config?.forecast_name,
              type: 'generate',
            },
            deploy_forecast_config: {
              status: 'new',
              name: config?.forecast_name,
              type: 'deploy',
            },
          };
          tableRows.push(rowObject);
        }
      });
    }

    return { columnDefs, tableRows };
  } catch (e) {
    console.log('Error in format forecast configs:', e);
    return null;
  }
};

export const formatCustomModules = (modulesData: any) => {
  try {
    let columnDefs: any[] = [
      {
        headerName: 'Module Name',
        field: 'module_name',
        width: 150,
        headerTooltip: 'Custom Module Name',
      },

      {
        headerName: 'Edit',
        field: 'edit_custom_module',
        headerTooltip: 'Edit custom module',
        cellRendererFramework: ModuleConfidenceCell,
        hide: false,
      },
      {
        headerName: 'Delete',
        field: 'delete_custom_module',
        headerTooltip: 'Delete custom module',
        cellRendererFramework: ModuleConfidenceCell,
        hide: false,
      },
    ];
    let tableRows: any[] = [];
    if (modulesData && modulesData?.length) {
      modulesData.forEach((record: any) => {
        if (module && record?.Module_Name) {
          let rowObject = {
            module_name: record?.Module_Name,
            edit_custom_module: { type: 'edit', module_name: record?.Module_Name },
            delete_custom_module: { type: 'delete', module_name: record?.Module_Name },
          };
          tableRows.push(rowObject);
        }
      });
    }
    return { columnDefs, tableRows };
  } catch (e) {
    console.log('Error in format custom modules:', e);
    return null;
  }
};
export const dataUrlToImage = async (dataUrl: string, fileName: string) => {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  const file = new File([blob], fileName, { type: 'image/png' });
  const image = URL.createObjectURL(file);
  return { image, file };
};

export const onLogout = () => {
  sessionStorage.clear();
};

export const formatAppThemeConfigs = (configs: any) => {
  try {
    let columnDefs: any[] = [
      {
        headerName: 'App Theme Name',
        field: 'app_theme_name',
        width: 150,
        headerTooltip: 'Name of App Theme Configuration',
      },
      {
        headerName: 'Status',
        field: 'status_app_theme_config',
        headerTooltip: 'This is the current apply status value of a theme',
        width: 150,
      },
      {
        headerName: 'Edit',
        field: 'edit_app_theme_config',
        headerTooltip: 'Edit App Theme Config Form Values',
        cellRendererFramework: AppThemeConfidenceCell,
        hide: false,
      },

      {
        headerName: 'Delete',
        field: 'delete_app_theme_config',
        headerTooltip: 'Delete an App Theme',
        cellRendererFramework: AppThemeConfidenceCell,
        hide: false,
      },
      {
        headerName: 'Apply',
        field: 'apply_app_theme_config',
        headerTooltip: 'Click Apply button against any theme configuration to apply it',
        cellRendererFramework: AppThemeConfidenceCell,
        width: 150,
      },
    ];
    let activeAppTheme: any = defaultTheme || null;
    let tableRows: any[] = [];
    if (configs && configs?.length) {
      configs.forEach((config: any) => {
        if (config && config?.APPTheme_Name) {
          if (config?.Apply_Status) {
            activeAppTheme = {
              ...defaultTheme,
              chartColors: config?.Chart_Colors?.length
                ? config?.Chart_Colors.split(',')
                : defaultTheme?.chartColors,
              companyName: config?.Company_Name ? config?.Company_Name : defaultTheme?.companyName,
              buttonPrimary: config?.Button_Primary
                ? config?.Button_Primary
                : defaultTheme?.buttonPrimary,
              buttonSecondary: config?.Button_Secondary
                ? config?.Button_Secondary
                : defaultTheme?.buttonSecondary,
              buttonTertiary: config?.Button_Tertiary
                ? config?.Button_Tertiary
                : defaultTheme?.buttonTertiary,
              appLogo: config?.App_Logo ? config?.App_Logo : defaultTheme?.appLogo,
              companyLogo: config?.Company_Logo ? config?.Company_Logo : defaultTheme?.companyLogo,
              applyStatus: config?.Apply_Status,
            };
          }
          let rowObject = {
            app_theme_name: config?.APPTheme_Name,
            status_app_theme_config: config?.Apply_Status ? 'Applied' : 'Not Applied',
            edit_app_theme_config: { type: 'edit', app_theme_name: config?.APPTheme_Name },
            delete_app_theme_config: { type: 'delete', app_theme_name: config?.APPTheme_Name },
            apply_app_theme_config: { type: 'apply', app_theme_name: config?.APPTheme_Name },
          };
          tableRows.push(rowObject);
        }
      });
    }

    return { columnDefs, tableRows, activeAppTheme };
  } catch (e) {
    console.log('Error in format app theme configs:', e);
    return null;
  }
};
