import api from 'services/api';

export const callApi = async (path: string, params: any = {}) => {
  const response = await api.get<any>(`${path}`, params);
  return response.data;
};

export const putTestData = async (path: string, payload: any = {}, options: any = {}) => {
  const response = await api.post<any>(path, payload, options);
  return response.data;
};
