import React, { useState, useEffect, FC } from 'react';
import { Formik, useFormikContext } from 'formik';
import { FilterData } from './Data/data';
import FilterListing from './FilterListing/FilterListing';
import { Button, Grid } from '@mui/material';
import { useGetClassId, useGetDepartment, useGetStoreId, useGetSubCategory } from 'services';
import { useLocation } from 'react-router-dom';
import Wrapper from 'views/DemandForcast/components/Wrapper';
import Section from 'components/Section';
import { defaultAllocation, defaultValues, defaultForecast } from 'utils/constant';
import { FilterContentProps } from './FilterContentProps';
import './Filter.css';
import SimpleBarReact from 'simplebar-react';
import 'simplebar/src/simplebar.css';

import { useSelector, useDispatch } from 'react-redux';
import { setFilterState } from 'redux/action/globalFilter';

export const Content: FC<FilterContentProps> = props => {
  const { appTheme } = props;
  const { values, setValues } = useFormikContext<any>();
  const subTabsState = useSelector((state: any) => state.subTabsState);
  const location: any = useLocation();
  const dispatch = useDispatch();
  const filterState = useSelector((state: any) => state.filterState);

  useEffect(() => {
    if (location.pathname.includes('salesOverview')) {
      setValues(filterState?.salesOverviewFilter);
    } else if (location.pathname.includes('forecast')) {
      setValues(filterState?.forecastFilter);
    } else if (location.pathname.includes('preSeason')) {
      setValues(filterState?.preSeasonFilter);
    } else if (location.pathname.includes('inSeason')) {
      setValues(filterState?.inSeasonFilter);
    } else if (location.pathname.includes('allocation')) {
      setValues(filterState?.allocationFilter);
    } else if (location.pathname.includes('customReport')) {
      setValues(filterState?.customReportFilter);
    } else {
      setValues(filterState?.defaultFilter);
    }
  }, [filterState]);

  useGetClassId(
    values?.start_year,
    values?.start_month,
    values?.end_year,
    values?.end_month,
    values?.subCategory,
    values?.department
  );
  useGetStoreId(
    values?.classId,
    values?.start_year,
    values?.start_month,
    values?.end_year,
    values?.end_month,
    values?.department,
    values?.subCategory
  );
  useGetSubCategory(
    values?.start_year,
    values?.start_month,
    values?.end_year,
    values?.end_month,
    values?.department
  );

  useGetDepartment(values?.start_year, values?.start_month, values?.end_year, values?.end_month);

  const handleFormSubmit = (values: any) => {
    setValues(values)
    dispatch(
      setFilterState({
        productCount:
          values?.department?.length + values?.subCategory?.length + values?.classId?.length,
        locationCount: values?.storeId?.length,
        [values?.module]: { ...values },
      })
    );
  };
  return (
    <Wrapper title="">
      <>
        <Grid container spacing={2} className="contentGrid">
          <Grid item md={12} className="filter-container">
            {/* <Section noPadding={true}> */}
            <SimpleBarReact style={{ maxHeight: '70vh' }}>
              <Grid container spacing={2} className="filter-content-layout">
                <Grid item md={12}>
                  <Formik
                    enableReinitialize
                    initialValues={values}
                    onSubmit={(values: any) => {
                      handleFormSubmit(values);
                    }}
                  >
                    {({ submitForm, values }) => {
                      return (
                        <>
                          {FilterData &&
                            FilterData.filter(
                              item => item.label === subTabsState?.selectedSubTab?.title
                            ).map((item, index) => {
                              return (
                                <div className="filter-label" key={item?.label}>
                                  {/* <span className="nav-items-title">{item?.label}</span> */}

                                  <ul className="list-unstyled components mb-4 nav-item-ul">
                                    {item?.navs &&
                                      item?.navs.map((nav: any, navIndex: any) => {
                                        return (
                                          <FilterListing
                                            nav={nav}
                                            key={navIndex}
                                            values={values}
                                            setValues={setValues}
                                          />
                                        );
                                      })}
                                  </ul>
                                </div>
                              );
                            })}
                          {
                            <div id="filter-container-button">
                              <Button
                                variant="contained"
                                type="submit"
                                style={{
                                  backgroundColor: 'white',
                                  fontFamily: 'SFProRoundedRegular',
                                  border: '1px solid grey',
                                  color: 'grey',
                                }}
                                onClick={() => {
                                  props.onCancelFilter();
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                type="submit"
                                style={{
                                  backgroundColor: appTheme?.buttonPrimary,
                                  fontFamily: 'SFProRoundedRegular',
                                  marginLeft: '1rem',
                                }}
                                onClick={() => {
                                  submitForm();
                                  props.onApplyFilter();
                                }}
                              >
                                Save Selection
                              </Button>
                            </div>
                          }
                        </>
                      );
                    }}
                  </Formik>
                </Grid>
              </Grid>
            </SimpleBarReact>
            {/* </Section> */}
          </Grid>
        </Grid>
      </>
    </Wrapper>
  );
};
