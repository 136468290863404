import { createTheme, ThemeOptions } from '@mui/material';

const themeOptions: ThemeOptions = {
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'SFProRoundedLight',
  },
  // palette: {
  //   mode: 'dark',
  //   background: {
  //     default: '#000',
  //     paper: '#2C3032',
  //   },
  //   primary: {
  //     main: '#000',
  //     contrastText: '#fff',
  //     light: '#A8B1B8',
  //     dark: '#000',
  //   },
  //   secondary: {
  //     main: '#2C3032',
  //     light: '#A8B1B8',
  //     contrastText: '#fff',
  //     dark: '#000',
  //   },
  //   text: {
  //     primary: '#fff',
  //     secondary: '#A8B1B8',
  //   },
  // },
};

export const theme = createTheme(themeOptions);
