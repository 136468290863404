import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
const FilterTabs = (props: any) => {
  const { SideBarData, handleFilterSelection, filterState, appTheme } = props;

  return (
    <div className="global-filter-container">
      {SideBarData?.filters?.map((filter: any) => {
        return (
          <div
            className="global-filter-item"
            onClick={() => {
              handleFilterSelection(filter);
            }}
          >
            <div
              className="global-filter-button"
              style={{ backgroundColor: appTheme?.buttonPrimary }}
              onMouseEnter={(e: any) => {
                e.target.style.backgroundColor = appTheme?.buttonTertiary;
              }}
              onMouseLeave={(e: any) => {
                e.target.style.backgroundColor = appTheme?.buttonPrimary;
              }}
            >
              {filter?.title.split(' ')[0]}
              <FilterAltOutlinedIcon style={{ marginLeft: '5px', fontSize: '21px' }} />
              {/* TODO filter count logic */}
              {filter?.title.split(' ')[0] === 'Product' ? (
                <span className="global-filter-count" style={{ color: appTheme?.buttonPrimary }}>
                  {filterState.productCount}
                </span>
              ) : filter?.title.split(' ')[0] === 'Location' ? (
                <span className="global-filter-count" style={{ color: appTheme?.buttonPrimary }}>
                  {filterState.locationCount}
                </span>
              ) : (
                <span className="global-filter-count" style={{ color: appTheme?.buttonPrimary }}>
                  4
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FilterTabs;
