import { useQuery, useMutation, QueryClient, QueryCache } from 'react-query';
import { putTestData, callApi } from 'services/apiCalls';
import { formatForecastConfigs } from 'utils/util';
interface deleteConfig {
  table_name: string;
  forecast_name: string;
}
function useGetForecastConfigurations() {
  const path = `/config_screen/getConfigReport`;
  const { data, isLoading, isSuccess, refetch, isRefetching } = useQuery(
    ['getForecastConfigReport'],

    () =>
      putTestData(path, {
        table_name: 'config_history',
      })
  );

  const { columnDefs, tableRows }: any = formatForecastConfigs(data);
  return {
    columnDefs,
    tableRows,
    configurations: data,
    isLoading,
    getConfigSuccess: isSuccess,
    refetchConfigs: refetch,
    fetchConfigStatus: isRefetching,
  };
}

function useGetProductGrain(payload: any) {
  const path = `/config_screen/getDistinctValues`;
  const { data, isLoading, isSuccess, refetch, isRefetching } = useQuery(['productGrain'], () =>
    putTestData(path, payload)
  );
  return {
    productData: data,
    isLoading,
    productGrainSuccess: isSuccess,
    refetch,
    fetchStatus: isRefetching,
  };
}

function useGetLocationGrain(payload: any) {
  const path = `/config_screen/getDistinctValues`;
  const { data, isLoading, isSuccess, refetch, isRefetching } = useQuery(['locationGrain'], () =>
    putTestData(path, payload)
  );
  return {
    locationData: data,
    isLoading,
    productGrainSuccess: isSuccess,
    refetch,
    fetchStatus: isRefetching,
  };
}

function useCreateForecastConfigurations() {
  const path = `/config_screen/createConfigReport`;
  const { data, mutate, isLoading, isError, isSuccess } = useMutation({
    mutationFn: (payload: any) => {
      const forecastData: any = {
        ...payload,
      };
      return putTestData(path, forecastData);
    },
    onSuccess: async data => {},
    onError: async () => {},
  });

  return {
    isError,
    isSuccess,
    data,
    mutate,
    isLoading,
  };
}

function useUpdateForecastConfigurations() {
  const path = `/config_screen/updateConfigReport`;
  const { data, mutate, isLoading, isError, isSuccess } = useMutation({
    mutationFn: (payload: any) => {
      const forecastData: any = {
        ...payload,
      };
      return putTestData(path, forecastData);
    },
    onSuccess: async data => {},
    onError: async () => {},
  });
  return {
    isError,
    isSuccess,
    data,
    mutate,
    isLoading,
  };
}
function useDeleteForecastConfigurations() {
  const path = `/config_screen/deleteConfigReport`;
  const { data, mutate, isLoading, isError, isSuccess } = useMutation({
    mutationFn: (payload: any) => {
      const forecastData: deleteConfig = {
        forecast_name: payload?.config_name,
        table_name: 'config_history',
      };
      return putTestData(path, forecastData);
    },
    onSuccess: async data => {},
    onError: async () => {},
  });
  return {
    isError,
    isSuccess: isSuccess,
    data,
    mutate,
    isLoading,
  };
}
export {
  useGetForecastConfigurations,
  useGetLocationGrain,
  useGetProductGrain,
  useCreateForecastConfigurations,
  useUpdateForecastConfigurations,
  useDeleteForecastConfigurations,
};
