export const months = [
  {
    label: 'Jan',
    value: 'JAN',
  },
  {
    label: 'Feb',
    value: 'FEB',
  },
  {
    label: 'Mar',
    value: 'MAR',
  },
  {
    label: 'April',
    value: 'APR',
  },
  {
    label: 'May',
    value: 'MAY',
  },
  {
    label: 'Jun',
    value: 'JUN',
  },
  {
    label: 'Jul',
    value: 'JUL',
  },
  {
    label: 'Aug',
    value: 'AUG',
  },
  {
    label: 'Sep',
    value: 'SEP',
  },
  {
    label: 'Oct',
    value: 'OCT',
  },
  {
    label: 'Nov',
    value: 'NOV',
  },
  {
    label: 'Dec',
    value: 'DEC',
  },
];

export const years = [
  {
    label: '2019',
    value: '2019',
  },
  {
    label: '2020',
    value: '2020',
  },
  {
    label: '2021',
    value: '2021',
  },
  {
    label: '2022',
    value: '2022',
  },
];
