import { MenuItem, TextField } from '@mui/material';
import { FC } from 'react';
import { getError, getFieldId } from '../field-helper';
import { Option, OptionsFieldProps } from '../form-field.props';

const SelectField: FC<OptionsFieldProps> = ({
  field,
  form,
  label,
  options,
  fullWidth,
  required,
  disabled,
  notifyChange,
  helperText,
  error: propError,
  ...props
}: OptionsFieldProps) => {
  const { name, value, onChange, onBlur } = field;

  const { error, hasError } = getError(field, form);

  const fieldId = getFieldId(name);

  return (
    <TextField
      select
      variant="outlined"
      size="small"
      name={name}
      id={fieldId}
      label={label}
      fullWidth={fullWidth}
      value={value ?? ''}
      helperText={error}
      error={hasError}
      onChange={e => {
        onChange(e);
        if (typeof notifyChange === 'function') {
          notifyChange((e.target || e.currentTarget).value);
        }
      }}
      onBlur={onBlur}
      disabled={disabled}
      {...props}
    >
      {(options || []).map((option: Option) => (
        <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
          {option.label || '[empty]'}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectField;
