import { useQuery } from 'react-query';
import { putTestData } from 'services/apiCalls';

export default function useGetClassId(
  class_list: string[],
  start_year: string,
  start_month: string,
  end_year: string = '',
  end_month: string = '',
  dept_list: string[] = [],
  sub_category_list: string[] = []
) {
  const path = `filters/getStoreId`;
  const { data, isLoading } = useQuery(
    [
      'storeId',
      class_list,
      start_year,
      start_month,
      end_year,
      end_month,
      dept_list,
      sub_category_list,
    ],
    () =>
      putTestData(path, {
        class_list,
        start_year,
        start_month,
        end_year,
        end_month,
        dept_list,
        sub_category_list,
      }),
    {
      enabled: !!start_year && !!start_month && Array.isArray(class_list) && !!class_list.length,
    }
  );
  const storeIdOptions: any[] = [];
  if (Array.isArray(data) && data.length) {
    data.forEach((item: any) => {
      storeIdOptions.push({ label: item.storeId, value: item.storeId });
    });
  }
  return { storeIdOptions, isLoading };
}
