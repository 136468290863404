import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { ProfileButtonStyles } from '../ProfileButton';
export const useProfileMenuStyles = (appTheme: any) =>
  makeStyles(
    (theme: Theme) => ({
      inset: {
        inset: `${theme.spacing(0.5, 0, 0, -1)} !important`,
      },
      largeAvatar: (props: ProfileButtonStyles) => ({
        height: theme.spacing(9),
        width: theme.spacing(9),
        margin: '8px',
        borderRadius: '50%',
        backgroundColor: appTheme.profile,
        textAlign: 'center',
      }),
      largeAvatarContent: {
        lineHeight: '2.1',
        fontSize: '36px',
        color: theme.palette.primary.contrastText,
      },
      infoPopup: {
        display: 'flex',
        minWidth: '150px',
        flexDirection: 'column',
        pointerEvents: 'none',
        marginBottom: theme.spacing(1),
        '&:hover': {
          background: '#FFFFFF !important',
        },
        '&:focus': {
          background: '#FFFFFF !important',
        },
      },
      spacing: {
        minWidth: theme.spacing(1),
      },
      menuItem: {
        fontSize: '14px',
        padding: '8px 16px',
      },
      caption: {
        color: '#a6a6a6',
      },
      actions: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
      },
    }),
    { name: 'ProfileMenu' }
  )(appTheme);
