import { useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { ICellRendererParams } from 'ag-grid-community';
import { CircularProgress } from '@mui/material';
import DeleteIcon from 'images/trash-sharp-icon.svg';
import { useDeleteForecastConfigurations, useGetForecastConfigurations } from 'services';
import EditIcon from 'images/edit-icon.svg';
import { setConfigStatus } from 'redux/action/configurations';
export default function ConfidenceCell(params: ICellRendererParams) {
  const dispatch = useDispatch();
  const {
    mutate: mutateDelete,
    isLoading: isLoadingDelete,
    isSuccess: isSuccessDelete,
    data,
  } = useDeleteForecastConfigurations();
  const { refetchConfigs } = useGetForecastConfigurations();
  const appTheme = useSelector((state: any) => state.appTheme);
  const onEditConfig = () => {
    dispatch(setConfigStatus({ forecastConfigToEdit: params?.value?.name }));
  };
  const onDeleteConfig = () => {
    if (params && params?.value?.name) {
      let configToDelete = {
        config_name: params?.value?.name,
      };
      mutateDelete(configToDelete);
    }
  };
  const clickHandler = () => {
    if (params?.value?.type === 'edit') {
      onEditConfig();
    }
    if (params?.value?.type === 'delete') {
      onDeleteConfig();
    }
  };
  async function refetchApi() {
    refetchConfigs();
  }
  useEffect(() => {
    if (isSuccessDelete) {
      refetchApi();
    }
  }, [isSuccessDelete]);
  return (
    <Grid container spacing={1} alignItems="center" justifyItems={'start'}>
      <Grid item sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        {
          <div
            style={{
              color: 'black',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              fontFamily: 'SFProRoundedRegular',
              cursor: 'pointer',
            }}
          >
            {params?.value?.type === 'edit' && (
              <div onClick={clickHandler}>
                <img
                  style={{ width: '15px', color: '#ffff' }}
                  className={'config-edit-icon'}
                  src={EditIcon}
                  alt="icon"
                />
              </div>
            )}
            {params?.value?.type === 'delete' && (
              <>
                {isLoadingDelete ? (
                  <CircularProgress
                    color="secondary"
                    style={{
                      color: appTheme?.buttonPrimary,
                      width: '1.7rem',
                      height: '1.7rem',
                      marginTop: '0.5rem',
                    }}
                  />
                ) : (
                  <div onClick={clickHandler}>
                    <img
                      style={{ width: '15px', color: '#ffff' }}
                      className={'config-edit-icon'}
                      src={DeleteIcon}
                      alt="icon"
                    />
                  </div>
                )}
              </>
            )}
          </div>
        }
      </Grid>
    </Grid>
  );
}
