import { ACTIONS } from '../../constants/actionConstants';

export const setConfigStatus = (data: any) => {
  return {
    type: ACTIONS.SET_CONFIG_STATUS,
    data,
  };
};

export const resetConfigStatus = (data: any) => {
  return {
    type: ACTIONS.RESET_CONFIG_STATUS,
    data,
  };
};
