import { useQuery } from 'react-query';
import { putTestData } from 'services/apiCalls';

export default function useGetCategoryPerformance(
  start_year: string,
  start_month: string,
  end_year: string = '',
  end_month: string = '',
  class_list: string[] = [],
  store_list: string[] = [],
  grouping_level: string
) {
  const path = `sales_and_inventory_performance/categoryPerformance`;
  const { data, isLoading } = useQuery(
    [
      'categoryPerformance',
      class_list,
      start_year,
      start_month,
      end_year,
      end_month,
      store_list,
      grouping_level,
    ],
    () =>
      putTestData(path, {
        class_list,
        start_year,
        start_month,
        end_year,
        end_month,
        store_list,
        grouping_level,
      }),
    {
      enabled:
        !!start_year &&
        !!start_month &&
        !!class_list.length &&
        Array.isArray(store_list) &&
        !!store_list.length,
    }
  );
  return {
    data,
    isLoading,
  };
}
