import { useMutation } from 'react-query';
import { putTestData } from 'services/apiCalls';

export default function useSaveAllocationData() {
  const path = `first_allocation/AdjustedInventoryTableUpdate`;
  const { data, mutate, isLoading, isError, isSuccess } = useMutation(adjusted_inventory_records =>
    putTestData(path, { adjusted_inventory_records })
  );

  return {
    isError,
    isSuccess,
    data,
    mutate,
    isLoading,
  };
}
