import { useQuery } from 'react-query';
import { putTestData } from 'services/apiCalls';

export default function useGetSubCategory(
  start_year: string,
  start_month: string,
  end_year: string = '',
  end_month: string = '',
  dept_list: string[] = []
) {
  const path = `filters/getSubCategory`;
  const { data, isLoading } = useQuery(
    ['subCategory', start_year, start_month, end_year, end_month, dept_list],
    () => putTestData(path, { start_year, start_month, end_year, end_month, dept_list }),
    { enabled: !!start_year && !!start_month && Array.isArray(dept_list) && !!dept_list.length }
  );
  const subCategoryOptions: any[] = [];
  if (Array.isArray(data) && data.length) {
    data.forEach((item: any) => {
      subCategoryOptions.push({ label: item.sub_category, value: item.sub_category });
    });
  }
  return { subCategoryOptions, isLoading };
}
