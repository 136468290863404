export const defaultValues = {
  classId: ['1900051', '1500023'],
  storeId: [
    '1700004',
    '11500078',
    '8000046',
    '10600069',
    '5600002',
    '1300003',
    '10200080',
    '2400005',
    '100005',
    '3400001',
    '3600010',
  ],
  start_month: 'FEB',
  start_year: '2022',
  end_month: 'SEP',
  end_year: '2022',
  department: ['Dec Home Textiles', 'Gourmet'],
  subCategory: ['THROWS', 'DECORATIVE PILLOW'],
};

export const defaultAllocation = {
  classId: ['300035', '5000033', '4100011', '4800022', '1200070', '5000053'],
  storeId: [
    '1700004',
    '3400013',
    '1700005',
    '100005',
    '7400019',
    '2400005',
    '3',
    '3400001',
    '8000043',
    '5600002',
    '10900088',
    '10200073',
    '10100073',
    '2800101',
    '1400001',
    '10900071',
    '4300010',
    '10700077',
    '1300003',
    '1900012',
    '5200003',
    '5200005',
    '3800005',
    '3800011',
    '6600013',
    '11100079',
    '3600010',
    '8000046',
    '8000045',
    '4200010',
    '10200080',
    '10600069',
    '11000071',
    '4900005',
  ],
  start_month: 'FEB',
  start_year: '2022',
  end_month: 'SEP',
  end_year: '2022',
  department: ['Young Mens', 'Boys 8 to 20 Apparel', 'Better Mens Sportswear', 'Baby Apparel'],
  subCategory: [
    'YM SS KNIT TOPS',
    'YM SS GRAPHIC TEES',
    'BOYS 8 TO 20 ACT SHORT BTMS',
    'MENS BETTER SS TEES',
    'BABY BASICS SS LW',
    'BOYS 8 TO 20 SPRTSWR SS TOPS',
  ],
};

export const defaultForecast = {
  classId: ['5100048', '8700290'],
  storeId: [
    '4900005',
    '5200005',
    '10100073',
    '8000045',
    '100005',
    '4200010',
    '1900012',
    '3800011',
    '10900071',
    '7400019',
    '4300010',
  ],
  start_month: 'FEB',
  start_year: '2022',
  end_month: 'SEP',
  end_year: '2022',
  department: ['Better Mens Sportswear'],
  subCategory: ['MENS BETTER SS KNITS', 'MENS BETTER SS POLOS'],
};
export const chartColorTheme = {
  colors: ['#7030A0', '#BB7FB7', '#D999FF', '#fad2e7', '#00A854'],
  variantShades: ['#dbabd8', '#f9f0ff', '#f7f2fa'],
  buttonPrimary: '#7030A0',
  buttonSecondary: '#8c51b8',
  buttonTertiary: '#7e3bb1',
  profile: '#7030A0',
  slider: '#f9f9f9',
  sliderFiller: '#e0e0e0',
  appBackground: '#ffffff',
  appSelectedBackground: '#f5f5f5',
  tableHeads: ['#cccaca', '#dbd9d9'],
};
