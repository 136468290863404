import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AlertDialog from 'components/AlertDialog';
import { Alert } from '@mui/material';
import './PageWrapper.css';
import TopNavBar from './TopNavBar/TopNavBar';
import SideBar from './SideBar/SideBar';
import { Formik } from 'formik';
import SimpleBarReact from 'simplebar-react';
import { Backdrop, CircularProgress } from '@mui/material';
import { lazy, Suspense } from 'react';
import 'simplebar/src/simplebar.css';
import FilterView from '../FilterView/FilterView';
import { defaultDBAppTheme } from 'constants/defaultAppTheme';
import { setSubTabsState } from 'redux/action/subTabSelections';
import { setAppTheme } from 'redux/action/appTheme';
import LandingPage from 'views/LandingPage';
import { useGetAppThemeConfigurations, useCreateAppThemeConfigurations } from 'services';
import RequireAuth from 'components/AuthComponent/AuthComponent';
import LoginPage from 'views/LoginPage';
import { onLogout } from 'utils/util';
const LoadingPage = lazy(() => import('views/LoadingPage/LoadingPage'));
const ControlTowerWrapper = lazy(() => import('views/ControlTowerWrapper/ControlTowerWrapper'));
const initialValues: any = {
  classId: [],
  storeId: [],
  start_month: '',
  start_year: '',
  end_month: '',
  end_year: '',
  department: [],
  subCategory: [],
};

const PageWrapper = (props: any) => {
  const {
    root,
    headerBar,
    viewportContainer,
    rightSideContainer,
    simpleBarHeight,
    centerViewWrapper,
  } = props;
  const [email, setEmail] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const dispatch = useDispatch();
  const subTabsState = useSelector((state: any) => state.subTabsState);
  const appThemeState = useSelector((state: any) => state.appTheme);
  const {
    activeAppTheme,
    appThemeData,
    isLoading: AppThemeLoading,
    getAppThemeSuccess,
    refetchAppTheme,
    refetchAppThemeStatus,
  } = useGetAppThemeConfigurations();
  const {
    data,
    mutate,
    isLoading,
    isError,
    isSuccess: isSuccessCreateDefaultTheme,
  } = useCreateAppThemeConfigurations();
  useEffect(() => {
    if ((getAppThemeSuccess || refetchAppThemeStatus) && appThemeData) {
      createDefaultAppTheme();
      getFaviconEl();
      dispatch(setAppTheme(activeAppTheme));
    }
  }, [getAppThemeSuccess, refetchAppThemeStatus]);
  useEffect(() => {
    if (isSuccessCreateDefaultTheme) {
      refetchAppTheme();
    }
  }, [isSuccessCreateDefaultTheme]);

  useEffect(() => {
    let email: any = '';

    // if (sessionStorage.userId && JSON.parse(sessionStorage.userId).username) {
    //   email =
    //     JSON.parse(sessionStorage.userId).username + '@' + appThemeState?.companyName + '.com';
    //   setEmail(email);
    // }
  }, [appThemeState]);
  function getFaviconEl() {
    const favicon: any = document.getElementById('favicon');
    if (favicon && activeAppTheme && activeAppTheme?.appLogo) {
      favicon.href = activeAppTheme?.appLogo;
    }
  }
  const createDefaultAppTheme = () => {
    if (Array.isArray(appThemeData) && appThemeData.length) {
      let defaultAppTheme = appThemeData?.filter(
        (item: any) => item?.APPTheme_Name === 'Default App Theme'
      );
      if (!defaultAppTheme.length && defaultDBAppTheme) {
        mutate({ ...defaultDBAppTheme });
      }
    }
  };
  const onFilterAction = () => {
    const newSelectedSubTabs = subTabsState?.selectedSubTabs.filter(
      (item: any) => item?.isFilter === false
    );
    dispatch(
      setSubTabsState({
        selectedSubTabs: newSelectedSubTabs,
        selectedSubTab: subTabsState?.prevSelectedSubTab,
        filterStatus: false,
      })
    );
  };
  const { pathname } = useLocation();
  const history = useHistory();

  const getComponent = () => {
    if (pathname === '/') {
      return <RequireAuth Component={LandingPage} />;
    } else if (pathname === '/login') {
      return <LoginPage />;
    } else if (pathname.includes('controlTower')) {
      return <ControlTowerWrapper />;
    } else {
      return (
        <div className={root}>
          <div className={headerBar}>
            <div
              style={{ background: appThemeState?.appBackground }}
              className="wrapper d-flex align-items-stretch"
            >
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={values => {
                  console.log(values);
                }}
              >
                {({ values }) => (
                  <>
                    <SideBar
                      navs={props.navs}
                      setOpenAlert={setOpenAlert}
                      isFilterActive={subTabsState?.filterStatus}
                    />
                    <TopNavBar
                      navs={props.navs}
                      profileButtonProps={{
                        userInfo: {
                          firstName: email,
                        },
                        menuActions: [
                          {
                            handler: () => {
                              onLogout();
                              history.push('/login');
                            },
                            label: 'logout',
                          },
                        ],
                        variant: 'basic',
                      }}
                      setOpenAlert={setOpenAlert}
                      isFilterActive={subTabsState?.filterStatus}
                    >
                      {subTabsState?.selectedSubTab?.isFilter ? (
                        <FilterView onFilterAction={onFilterAction} appTheme={appThemeState} />
                      ) : (
                        <div className={viewportContainer}>
                          <div className={rightSideContainer}>
                            <SimpleBarReact className={simpleBarHeight}>
                              <div className={centerViewWrapper}>{props.children}</div>
                            </SimpleBarReact>
                          </div>
                        </div>
                      )}
                      {
                        <AlertDialog
                          appTheme={appThemeState}
                          open={openAlert}
                          message={
                            <Alert severity="error">Please click Save or Cancel to proceed</Alert>
                          }
                          onConfirm={() => setOpenAlert(false)}
                        />
                      }
                    </TopNavBar>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      );
    }
  };
  return <>{getComponent()}</>;
};
export default PageWrapper;
