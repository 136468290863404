import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { getSubTabsState } from './subTabSelections';
import { getFilterState } from '../reducer/globalFilterReducer';
import { getApiStatus } from '../reducer/apiStatus';
import { getConfigStatus } from '../reducer/configurations';
import { getAppTheme, getAppThemeToUpdate } from '../reducer/appTheme';
import { getCustomModuleStatus } from '../reducer/customModule';

const rootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    subTabsState: getSubTabsState,
    filterState: getFilterState,
    APIStatus: getApiStatus,
    configStatus: getConfigStatus,
    appTheme: getAppTheme,
    getAppThemeToUpdate,
    customModuleState: getCustomModuleStatus,
  });

export default rootReducer;
