import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ProfileButtonStyles } from './ProfileButton.props';

export const useProfileButtonStyles = makeStyles(
  (theme: Theme) => ({
    avatar: (props: ProfileButtonStyles) => ({
      color: theme.palette.primary.contrastText,
      backgroundColor: props.color || theme.palette.primary.main,
      '&$small': {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
      '&$large': {
        width: theme.spacing(7),
        height: theme.spacing(7),
      },
    }),
    noMargin: {
      margin: -8,
      padding: 8,
    },
    fit: {},
    label: {
      textTransform: 'none',
    },
    large: {},
    root: {
      '&$fit': {
        height: '100%',
        width: '100%',
      },
    },
    small: {},
  }),
  {
    name: 'ProfileButton',
  }
);
