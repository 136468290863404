import { MenuItem, Popover, Typography } from '@mui/material';
import React from 'react';
import { Avatar } from '@mui/material';
import { ProfileMenuProps } from './ProfileMenu.props';
import { useProfileMenuStyles } from './ProfileMenu.styles';
import UserProfileIcon from 'images/user-profile-icon.png';
export const ProfileMenu: React.FC<ProfileMenuProps> = props => {
  const {
    actions,
    anchorEl,
    onClose,
    open,
    color,
    appTheme,
    userInfo: { firstName, lastName, email, avatarUrl },
  } = props;

  const classes = useProfileMenuStyles({ color, appTheme });

  return (
    <Popover
      data-testid="profileMenu"
      anchorEl={anchorEl}
      keepMounted
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={onClose}
      classes={{ root: classes.inset }}
    >
      <MenuItem className={classes.infoPopup}>
        {avatarUrl ? (
          <Avatar className={classes.largeAvatar} src={avatarUrl} alt={firstName ?? email} />
        ) : (
          // <Avatar className={classes.largeAvatar}>
          //   <span className={classes.largeAvatarContent}>
          //     {firstName && firstName.charAt(0).toUpperCase()}
          //     {lastName && lastName.charAt(0).toUpperCase()}
          //   </span>
          // </Avatar>
          <img id="user-profile-pop" src={UserProfileIcon} alt="profile icon" />
        )}
        <Typography variant="subtitle1">
          {firstName} {lastName}
        </Typography>
        {email && (
          <Typography variant="caption" className={classes.caption}>
            {email}
          </Typography>
        )}
      </MenuItem>
      <div className={classes.actions}>
        {actions?.map(list => (
          <MenuItem
            key={list.label}
            data-testid="profileMenuAction"
            className={classes.menuItem}
            onClick={list.handler}
          >
            {list.icon && list.icon}
            <div className={classes.spacing} />
            {list.label}
          </MenuItem>
        ))}
      </div>
    </Popover>
  );
};
