import { Grid, Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function AllocationCell(params: ICellRendererParams) {
  const colId = params?.column?.getColId();
  const monthYear = colId?.split('--')[0];
  const devitaion = params.data[`${monthYear}--deviation`];
  const forecast = params.data[`${monthYear}--Forecast_Sales`];
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>{params.value}</Grid>
      {devitaion <= -50 && (
        <Grid item sx={{ display: 'flex' }}>
          <Tooltip
            title={
              <Grid container flexDirection={'column'} spacing={1}>
                <Grid item>Deviation: {devitaion}</Grid>
                <Grid item>Forecast Sales: {forecast}</Grid>
              </Grid>
            }
          >
            <ArrowDownwardIcon color="error" />
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
}
