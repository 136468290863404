import Axios from 'axios';
import { Configuration } from 'utils/configuration';
import registerRequestInterceptors from './registerRequestInterceptors';
import registerResponseInterceptors from './resgisterResponseInterceptors';

const config = new Configuration();

const api = Axios.create({
  baseURL: config.GetConfig('API_URL'),
});

registerRequestInterceptors(api);

registerResponseInterceptors(api);

export * from './helper';

export default api;
