import { Button, Grid } from '@mui/material';
import { SelectField, MultiSelectField } from 'components/form-fields';
import SelectFormField from 'components/form-fields/SelectFormField';
import { Form, Field } from 'formik';
import { months, years } from 'views/DemandForcast/utils';
import { useGetClassId, useGetDepartment, useGetStoreId, useGetSubCategory } from 'services';
const Filters = ({ setOpen, values, submitForm, appTheme }: any) => {
  const { classIds } = useGetClassId(
    values.start_year,
    values.start_month,
    values.end_year,
    values.end_month,
    values.subCategory,
    values.department
  );
  const { storeIdOptions } = useGetStoreId(
    values.classId,
    values.start_year,
    values.start_month,
    values.end_year,
    values.end_month,
    values.department,
    values.subCategory
  );
  const { subCategoryOptions } = useGetSubCategory(
    values.start_year,
    values.start_month,
    values.end_year,
    values.end_month,
    values.department
  );
  const { departmentOptions } = useGetDepartment(
    values.start_year,
    values.start_month,
    values.end_year,
    values.end_month
  );
  return (
    <>
      <Form style={{ width: '100%', height: '100%', padding: `16px 0px 16px 16px` }}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Field
              name="start_month"
              label={'Select Start Month'}
              component={SelectField}
              options={months}
              fullWidth
            />
          </Grid>
          <Grid item md={6}>
            <Field
              name="start_year"
              label={'Select Start Year'}
              component={SelectField}
              options={years}
              fullWidth
            />
          </Grid>
          <Grid item md={6}>
            <Field
              name="end_month"
              label={'Select End Month'}
              component={SelectField}
              options={months}
              fullWidth
            />
          </Grid>
          <Grid item md={6}>
            <Field
              name="end_year"
              label={'Select End Year'}
              component={SelectField}
              options={years}
              fullWidth
            />
          </Grid>
          <Grid item md={12}>
            <Field
              name="department"
              label={'Select Department'}
              component={MultiSelectField}
              options={departmentOptions}
              fullWidth
            />
          </Grid>
          <Grid item md={12}>
            <Field
              name="subCategory"
              label={'Select Sub-category'}
              component={MultiSelectField}
              options={subCategoryOptions}
              fullWidth
            />
          </Grid>
          <Grid item md={12}>
            <Field
              name="classId"
              label={'Select Class'}
              component={SelectFormField}
              options={classIds}
              fullWidth
            />
          </Grid>
          <Grid item md={12}>
            <Field
              name="storeId"
              label={'Select Store Id'}
              component={MultiSelectField}
              options={storeIdOptions}
              multiple
              fullWidth
            />
          </Grid>
          <Grid item container md={12} spacing={2}>
            <Grid item flex={1}></Grid>
            <Grid item>
              <Button
                variant="outlined"
                style={{
                  color: appTheme?.buttonPrimary,
                  border: `1px solid ${appTheme?.buttonPrimary}`,
                }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                type="submit"
                style={{ backgroundColor: appTheme?.buttonPrimary }}
                onClick={() => {
                  submitForm();
                }}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default Filters;
