
import React, { FC, useEffect } from 'react';
import { FilterListingProps } from './FilterListingProps'
import { Form, Field } from 'formik';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import 'simplebar/src/simplebar.css';

import AccordionContent from './AccordionContent';

const FilterListing: FC<FilterListingProps> = props => {
  const { nav, values, setValues } = props;

  return (
    <Form>
      <li className="active mt-3 mb3" key={nav?.title}>
        {nav?.subNavs ? (
          <div>
            {
              nav?.subNavs &&
              nav?.subNavs.map((subnav: any, subnavIndex: any) => (
                <>
                  { subnav?.title.includes('Year') || subnav?.title.includes('Month') ? 
                    <AccordionContent name={subnav?.name} title={subnav?.title} values={values} />
                  : 
                    <Accordion className='accordion-styles' key={subnav?.title} >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className='filter-title-accordion'>{subnav?.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionContent name={subnav?.name} title={subnav?.title} values={values} />
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  }
                </>
              ))
            }
          </div>
        ) : null}
      </li>
    </Form>
  );
}

export default FilterListing;