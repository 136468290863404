import NavCategoryIcon from 'images/nav-icon-category.svg';
import NavStoreIcon from 'images/nav-icon-store.svg';
import NavWeekIcon from 'images/nav-icon-week.svg';
import NavApprovedIcon from 'images/nav-icon-approved.svg';
import NavDraftIcon from 'images/nav-icon-draft.svg';
import NavPreSeasonIcon from 'images/nav-icon-preseason.svg';

export const SideBarData = {
  navs: [
    {
      title: 'Overview Dashboard',
      subTabTitle: 'Overview Dashboard',
      icon: 'nav-box',
      route: '/demandPlanning/salesOverview',
      key: 'Overview Dashboard',
      isFilter: false,
      isNav: true,
      isModule: true,
      subNavs: [
        {
          title: 'Overview​',
          subTabTitle: 'Overview Dashboard > Overview​',
          icon: NavPreSeasonIcon,
          route: '/demandPlanning/salesOverview',
          key: 'salesOverview-overview',
          isFilter: false,
        },
      ],
    },
    {
      title: 'Forecast Analysis',
      subTabTitle: 'Forecast Analysis',
      route: '/demandPlanning/forecastPerformance',
      icon: 'nav-box',
      key: 'Forecast Analysis',
      isFilter: false,
      isNav: true,
      isModule: true,
      subNavs: [
        {
          title: 'Accuracy Analysis​',
          subTabTitle: 'Forecast Analysis > Accuracy Analysis​',
          icon: NavPreSeasonIcon,
          route: '/demandPlanning/forecastPerformance',
          key: 'forecast-accuracy-analysis',
          isFilter: false,
        },
        // {
        //   title: 'Forecast vs Actual​​',
        //   subTabTitle: 'Forecast Analysis > Forecast vs Actual​​',
        //   icon: NavPreSeasonIcon,
        //   route: '/demandPlanning/forecastvsactual',
        //   key: 'forecast-forecastvsactual',
        //   isFilter: false,
        // },
        // {
        //   title: 'Forecast Drivers​​​',
        //   subTabTitle: 'Forecast Analysis > Forecast Drivers​​',
        //   icon: NavPreSeasonIcon,
        //   route: '/demandPlanning/forecastDrivers',
        //   key: 'forecast-forecast-drivers',
        //   isFilter: false,
        // },
        // {
        //   title: 'ForecastDecompose​​​',
        //   subTabTitle: 'Forecast Analysis > ForecastDecompose​​',
        //   icon: NavPreSeasonIcon,
        //   route: '/demandPlanning/forecastDecompose',
        //   key: 'forecast-forecastDecompose',
        //   isFilter: false,
        // },
      ],
    },

    {
      title: 'Demand Planning',
      subTabTitle: 'Demand Planning',
      route: '/preSeason',
      icon: 'nav-category',
      key: 'Demand Planning',
      isFilter: false,
      isNav: false,
      subNavs: [
        {
          title: 'Pre-Season',
          subTabTitle: 'Demand Planning > Pre-Season',
          icon: NavPreSeasonIcon,
          route: '/demandPlanning/preSeason',
          key: 'demand-preSeason',
          isFilter: false,
        },
        // {
        //   title: 'In-Season',
        //   subTabTitle: 'Demand Planning > In-Season',
        //   icon: NavPreSeasonIcon,
        //   route: '/demandPlanning/inSeason',
        //   key: 'demand-inSeason',
        //   isFilter: false,
        // },
      ],
    },
    {
      title: 'Allocation Planning',
      subTabTitle: 'Allocation Planning',
      route: '/demandPlanning/allocation',
      icon: 'nav-category',
      key: 'Allocation Planning',
      isFilter: false,
      isNav: true,
      subNavs: [
        {
          title: 'Initial Allocation',
          subTabTitle: 'Allocation Planning > Initial Allocation',
          icon: NavPreSeasonIcon,
          route: '/demandPlanning/allocation',
          key: 'allocation-initial-alloc',
          isFilter: false,
        },
      ],
    },
    {
      title: 'Customize module',
      subTabTitle: 'Customize module',
      route: '/demandPlanning/customizeModule',
      icon: 'nav-plusIcon',
      key: 'customizeModule',
      isFilter: false,
      isNav: true,
      subNavs: [],
    },
    {
      title: 'Configuration',
      subTabTitle: 'Configuration',
      route: '/demandPlanning/configuration',
      icon: 'nav-gear',
      key: 'configuration',
      isFilter: false,
      isNav: true,
      subNavs: [],
    },
  ],
  filters: [
    {
      title: 'Product Filter',
      subTabTitle: 'Product Filter',
      key: 'productFilter',
      isFilter: true,
    },
    {
      title: 'Location Filter',
      subTabTitle: 'Location Filter',
      key: 'locationFilter',
      isFilter: true,
    },
    {
      title: 'Timeline Filter',
      subTabTitle: 'Timeline Filter',
      key: 'timelineFilter',
      isFilter: true,
    },
  ],
};
