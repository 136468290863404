import { ACTIONS } from '../../constants/actionConstants';

const customModuleState: any = {
  moduleToEdit: null,
};

export const getCustomModuleStatus = (state = customModuleState, action: any) => {
  switch (action.type) {
    case ACTIONS.SET_CUSTOM_MODULE_STATUS:
      return { ...state, ...action.data };
    case ACTIONS.RESET_CUSTOM_MODULE_STATUS:
      return customModuleState;
    default:
      return state;
  }
};
