/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar, Button, IconButton, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { ProfileMenu } from '../ProfileMenu';

import { ProfileButtonProps } from './ProfileButton.props';
import { useProfileButtonStyles } from './ProfileButton.styles';
import UserProfileIcon from 'images/user-profile-icon.png';

const getButton = (
  props: ProfileButtonProps,
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  avatar: JSX.Element,
  firstName: string,
  classes: any
) => {
  const { fit, variant = 'normal' } = props;
  let button: any = null;

  if (variant === 'basic') {
    button = (
      <IconButton className={classes.noMargin} onClick={handleClick}>
        {avatar}
      </IconButton>
    );
  } else {
    const text = (
      <Typography data-testid="name" color="textPrimary">
        {firstName}
      </Typography>
    );

    const rootClassesOverrides = { label: classes.label, root: classes.root };
    const rootClassModifier = clsx({
      [classes.fit]: fit,
    });

    button = (
      <Button
        classes={rootClassesOverrides}
        className={rootClassModifier}
        onClick={handleClick}
        startIcon={avatar}
      >
        {text}
      </Button>
    );
  }

  return button;
};

export const ProfileButton: React.FC<ProfileButtonProps> = props => {
  let { menu, appTheme } = props;
  const {
    avatarSize,
    menuActions,
    onClick,
    userInfo: { avatarUrl, firstName = '', lastName },
    userInfo,
    color,
  } = props;
  const classes = useProfileButtonStyles({ color: appTheme?.buttonPrimary });

  // if (!firstName) {
  //   throw new Error(`"firstName" is required`);
  // }

  const avatarClass = clsx(classes.avatar, {
    [classes.large]: avatarSize === 'large',
    [classes.small]: avatarSize === 'small',
  });

  const avatar = avatarUrl ? (
    <Avatar className={avatarClass} data-testid="avatar" src={avatarUrl} alt={firstName} />
  ) : (
    // <Avatar className={avatarClass} data-testid="avatar">
    //   {firstName && firstName.charAt(0).toUpperCase()}
    //   {lastName && lastName.charAt(0).toUpperCase()}
    // </Avatar>
    <img id="user-profile" src={UserProfileIcon} alt="profile icon" />
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    setAnchorEl(e.currentTarget);
    onClick?.(e);
  };

  const handleMenuClose = () => setAnchorEl(null);

  let button = getButton(props, handleClick, avatar, firstName, classes);

  menu = menu ?? (
    <ProfileMenu
      anchorEl={anchorEl}
      onClose={handleMenuClose}
      open={open}
      userInfo={userInfo}
      color={color}
      appTheme={appTheme}
    />
  );
  menu = React.cloneElement(menu, {
    actions: menuActions,
    onClose: handleMenuClose,
    open,
    userInfo,
  });

  return (
    <>
      {button}
      {menu}
    </>
  );
};
