import React from "react";
import { ReactComponent as GearIcon } from 'images/nav-gear.svg';
import { ReactComponent as NavCategory } from 'images/nav-category.svg';
import { ReactComponent as NavDownIcon } from 'images/downArrow.svg';
import { ReactComponent as BoxIcon } from 'images/nav-box.svg';
import { ReactComponent as CheckIcon } from 'images/nav-check.svg';
import { ReactComponent as CalendarIcon } from 'images/nav-calendar.svg';
import { ReactComponent as CalculateIcon } from 'images/nav-calculate.svg';
import { ReactComponent as PlusIcon } from 'images/nav-plusIcon.svg';
import { ReactComponent as StickIcon } from 'images/nav-stick.svg';
import { ReactComponent as InfoIcon } from 'images/infoIcon2.svg'

import { ReactComponent as LayoutOne } from 'images/layout1.svg'
import { ReactComponent as LayoutTwo } from 'images/layout2.svg'
import { ReactComponent as LayoutThree } from 'images/layout3.svg'
import { ReactComponent as LayoutFour } from 'images/layout4.svg'
import { ReactComponent as LayoutFive } from 'images/layout5.svg'

import { ReactComponent as AccentureLogo } from 'images/accenture_logo.svg'

const ImageSvg = (props:any) => {
  const { name, style = {}, apptheme } = props;
  return (
    <div className={``} style={style}>
      {name === "nav-gear" ? <GearIcon fill={apptheme?.buttonPrimary} style={{ transform: 'scale(0.8)'}} /> : null}
      {name === "nav-category" ? <NavCategory fill={apptheme?.buttonPrimary} style={{ transform: 'scale(0.8)'}} /> : null}
      {name === "downArrow" ? <NavDownIcon fill={apptheme?.buttonPrimary} style={{ transform: 'scale(0.65)' }}/> : null}
      {name === "nav-box" ? <BoxIcon fill={apptheme?.buttonPrimary} style={{ transform: 'scale(0.8)'}} /> : null}
      {name === "nav-check" ? <CheckIcon fill={apptheme?.buttonPrimary} style={{ transform: 'scale(0.8)'}} /> : null}
      {name === "nav-calendar" ? <CalendarIcon fill={apptheme?.buttonPrimary} style={{ transform: 'scale(0.8)'}} /> : null}
      {name === "nav-calculate" ? <CalculateIcon fill={apptheme?.buttonPrimary} style={{ transform: 'scale(0.8)'}} /> : null}
      {name === "nav-plusIcon" ? <PlusIcon fill={apptheme?.buttonPrimary} style={{ transform: 'scale(0.8)'}} /> : null}
      {name === "nav-stick" ? <StickIcon fill={apptheme?.buttonPrimary} style={{ transform: 'scale(0.8)'}} /> : null}
      {name === "info-icon" ? <InfoIcon fill={apptheme?.buttonPrimary} style={{ transform: 'scale(0.8)'}} /> : null}

      {name === "layout-1" ? <LayoutOne stroke={apptheme?.buttonPrimary} /> : null}
      {name === "layout-2" ? <LayoutTwo stroke={apptheme?.buttonPrimary} /> : null}
      {name === "layout-3" ? <LayoutThree stroke={apptheme?.buttonPrimary} /> : null}
      {name === "layout-4" ? <LayoutFour stroke={apptheme?.buttonPrimary} /> : null}
      {name === "layout-5" ? <LayoutFive stroke={apptheme?.buttonPrimary} /> : null}

      {name === "accenture-logo" ? <AccentureLogo fill={apptheme?.buttonPrimary} width={22} height={22} /> : null}

    </div>
  );

}

export default ImageSvg;