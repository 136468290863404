import DemandPlanningIcon from 'images/demand-planning-icon.png';
import CreateAppIcon from 'images/create-application-icon.png';
import IbpIcon from 'images/ibp-icon.png';
import SopIcon from 'images/sop-icon.png';
import SupplyPlanningIcon from 'images/supply-planning-icon.png';
import ControlTower from 'images/control-tower.png';

export const landingPageData = [
  {
    name: 'Demand Planning',
    url: 'demandPlanning/salesOverview',
    icon: DemandPlanningIcon,
    title:'Demand Planning'
  },
  {
    name: 'Supply Planning',
    url: '/',
    icon: SupplyPlanningIcon,
    title:'Supply Planning'
  },
  {
    name: 'S&OP',
    url: '/',
    icon: SopIcon,
    title:'S&OP'
  },
  {
    name: 'IBP',
    url: '/',
    icon: IbpIcon,
    title:'IBP'
  },
  {
    name: 'Control Tower',
    url: '/controlTower/inboundLogistics',
    icon: ControlTower,
    title:'Intelligent Response System'
  },
  {
    name: 'Create Application',
    url: '/',
    icon: CreateAppIcon,
    title:'Create Application'
  },
];
