/* eslint-disable eqeqeq */
import moment from 'moment';
import { useQuery } from 'react-query';
import { putTestData } from 'services/apiCalls';

export default function usePreSeasonForecast(
  class_list: string[],
  start_year: string,
  start_month: string,
  end_year: string = '',
  end_month: string = '',
  store_list: string[] = [],
  dept_list: string[] = [],
  sub_category_list: string[] = []
) {
  const path = `forecast_pre_season/aggregationYearMonthLevel`;
  const { data, isLoading } = useQuery(
    [
      'aggregationYearMonthLevel',
      class_list,
      start_year,
      start_month,
      end_year,
      end_month,
      store_list,
      dept_list,
      sub_category_list,
    ],
    () =>
      putTestData(path, {
        class_list,
        start_year,
        start_month,
        end_year,
        end_month,
        store_list,
        dept_list,
        sub_category_list,
      }),
    {
      enabled:
        !!start_year &&
        !!start_month &&
        !!class_list.length &&
        Array.isArray(store_list) &&
        !!store_list.length,
    }
  );
  const month: any[] = [];
  const revenueFilteredMonth: any[] = [];
  const months: any[] = [];
  const actualSales: any[] = [];
  const forcastSales: any[] = [];
  const historicalSales: any[] = [];
  const historicalStock: any[] = [];
  const WMAPE: any[] = [];
  const BIAS: any[] = [];
  let accuracy = 0;
  let avgAccuracy = 0;
  let avgBias = 0;
  let totalBias = 0;
  if (Array.isArray(data) && data.length) {
    data.forEach((item: any) => {
      if (!months.includes(item.month)) {
        months.push(item.month);
      }
      // filter months >=july
      if (item.month && Number(moment().month(item.month).format('M')) >= 7) {
        revenueFilteredMonth.push(`${item.month}-${item.year}`);
      }
      month.push(`${item.month}-${item.year}`);
      actualSales.push(item.Actual_Sales);
      forcastSales.push(item.Forecast_Sales);
      WMAPE.push(item.WMAPE);
      historicalSales.push(item.Historical_Sales);
      historicalStock.push(item.Historical_Stock);
      accuracy = accuracy + item.ACCURACY;
      BIAS.push(item.BIAS);
      totalBias = totalBias + item.BIAS;
    });
    avgAccuracy = accuracy / data.length;
    avgBias = totalBias / data.length;
  }

  return {
    month,
    months,
    revenueFilteredMonth,
    historicalSales,
    historicalStock,
    actualSales,
    forcastSales,
    WMAPE,
    BIAS,
    avgAccuracy,
    preSeasonLoader: isLoading,
    avgBias,
  };
}
