import { useQuery, useMutation, QueryClient, QueryCache } from 'react-query';
import { putTestData, callApi } from 'services/apiCalls';

import NavCategoryIcon from 'images/nav-icon-category.svg';
import NavStoreIcon from 'images/nav-icon-store.svg';
import NavWeekIcon from 'images/nav-icon-week.svg';
import NavApprovedIcon from 'images/nav-icon-approved.svg';
import NavDraftIcon from 'images/nav-icon-draft.svg';
import NavPreSeasonIcon from 'images/nav-icon-preseason.svg';
import { formatCustomModules } from 'utils/util';
interface newModule {
  moduleName: string;
  icon: string;
  user?: string | 'admin';
}

function useCreateModule() {
  const path = `/custom_module/createCustomModule`;
  const { data, mutate, isLoading, isError, isSuccess } = useMutation({
    mutationFn: (payload: newModule) => {
      const moduleData: newModule = {
        ...payload,
        user: 'admin',
      };
      return putTestData(path, moduleData);
    },
    onSuccess: async data => {},
    onError: async () => {},
  });

  return {
    isError,
    isSuccess,
    data,
    mutate,
    isLoading,
  };
}

interface updateModule {
  moduleName: string;
  icon: string;
}

function useUpdateModule() {
  const path = `/custom_module/updateCustomModule`;
  const { data, mutate, isLoading, isError, isSuccess } = useMutation({
    mutationFn: (payload: updateModule) => {
      console.log('INSIDE API CALL',payload)
      const moduleData: updateModule = {
        ...payload,
      };
      return putTestData(path, moduleData);
    },
    onSuccess: async data => {},
    onError: async () => {},
  });

  return {
    isError,
    isSuccess,
    data,
    mutate,
    isLoading,
  };
}

function useGetModules() {
  const path = `/custom_module/getJsonConfig`;
  const { data, isLoading, isSuccess, refetch, isRefetching } = useQuery(['getModules'], () =>
    putTestData(path)
  );
  const { columnDefs, tableRows }: any = formatCustomModules(data);
  return {
    data,
    isLoading,
    isSuccess,
    refetch,
    fetchStatus: isRefetching,
    columnDefs,
    tableRows,
  };
}

interface deleteModule {
  moduleName: string;
}
function useDeleteModule() {
  const path = `/custom_module/deleteModuleData`;
  const { data, mutate, isLoading, isError, isSuccess } = useMutation({
    mutationFn: (payload: deleteModule) => {
      const moduleData: deleteModule = {
        ...payload,
      };
      return putTestData(path, moduleData);
    },
    onSuccess: async data => {},
    onError: async () => {},
  });

  return {
    isError,
    isSuccess,
    data,
    mutate,
    isLoading,
  };
}

export { useCreateModule, useUpdateModule, useGetModules, useDeleteModule };
