import {
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import React, { FC } from 'react';
import { getError, getFieldId } from '../field-helper';
import { OptionsFieldProps } from '../form-field.props';
import ClearIcon from '@mui/icons-material/Clear';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 400,
    },
  },
};

const SelectFormField: FC<OptionsFieldProps> = ({
  field,
  form,
  label,
  options,
  fullWidth,
  required,
  disabled,
  notifyChange,
  helperText,
  error: propError,
  ...props
}: OptionsFieldProps) => {
  const { name, value } = field;
  const { setFieldTouched, setFieldValue } = form;

  const { error, hasError } = getError(field, form);
  const fieldId = getFieldId(name);
  const labelId = `${fieldId}-outlined-label`;

  const isAllSelected = options.length > 0 && value.length === options.length;

  const handleChange = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setFieldTouched(name, true);
      setFieldValue(
        name,
        value.length === options.length ? [] : Array.from(options, x => x.value),
        true
      );
      return;
    }
    setFieldTouched(name, true);
    setFieldValue(name, value, true);
  };
  const handleClearClick = (field: any) => {
    setFieldValue(name, [], true);
  };

  return (
    <FormControl
      variant="outlined"
      error={!!propError || hasError}
      size="small"
      margin="none"
      fullWidth={fullWidth}
    >
      <InputLabel id={labelId} required={required}>
        {label}
      </InputLabel>

      <Select
        labelId="mutiple-select-label"
        multiple
        value={value}
        onChange={handleChange}
        label={label}
        renderValue={selected => {
          const selectedOptions: any = [];
          selected.forEach((element: any) => {
            const result: any = options.filter(obj => obj.value === element);
            if (Array.isArray(result) && result.length) {
              selectedOptions.push(result[0].label);
            }
          });
          return selectedOptions.join(',');
        }}
        MenuProps={MenuProps}
        sx={{
          '& .MuiSelect-iconOutlined': { display: value && value.length === 0 ? '' : 'none' },
          '&.Mui-focused .MuiIconButton-root': { color: 'primary.main' },
        }}
        endAdornment={
          <IconButton
            sx={{ visibility: value && value.length === 0 ? 'hidden' : 'visible' }}
            onClick={() => handleClearClick(field)}
          >
            <ClearIcon />
          </IconButton>
        }
      >
        <MenuItem value="all">
          <ListItemIcon>
            <Checkbox
              checked={isAllSelected}
              indeterminate={value?.length > 0 && value?.length < options.length}
            />
          </ListItemIcon>
          <ListItemText primary="Select All" />
        </MenuItem>
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            <ListItemIcon>
              <Checkbox checked={value.indexOf(option.value) > -1} />
            </ListItemIcon>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
      {(!!propError || error) && <FormHelperText>{helperText || error}</FormHelperText>}
    </FormControl>
  );
};

export default SelectFormField;
