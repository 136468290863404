import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export const customStyle = (appTheme: any) =>
  makeStyles(
    (theme: Theme) => ({
      listItemText: {
        fontSize: '0.8em', //drop down options text font
        fontFamily: 'SFProRoundedRegular',
      },
      root: {
        '&$checked': {
          color: appTheme?.buttonPrimary,
        },
      },
      checked: {},
      inputLabel: {
        fontSize: '16px',
        color: `${appTheme?.buttonPrimary} !important`,
      },
    }),
    { name: 'CustomStyle' }
  )(appTheme);
