import { makeStyles } from '@mui/styles';

export const useflexItemSpacerStyles = makeStyles(
  () => ({
    root: ({ flex }: any) => ({
      flex: flex ?? 1,
    }),
  }),
  { name: 'FlexItemSpacerStyles' }
);
