import React, { FC } from 'react';
import { Content } from './Content';
import { FilterProps } from './FilterProps';

const initialValues = {};

const Filter: FC<FilterProps> = props => {
  return (
    <Content
      onApplyFilter={props.onApplyFilter}
      onCancelFilter={props.onCancelFilter}
      appTheme={props?.appTheme}
    />
  );
};

export default Filter;
