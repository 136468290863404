// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';
// import 'fontsource-open-sans';
// import './fonts/SFProFont/SFPRODISPLAYBOLD.OTF';
// import './fonts/SFProFont/SF-Pro-Rounded-Thin.otf';
// import './fonts/SFProFont/SF-Pro-Rounded-Regular.otf';
// import './fonts/SFProFont/SF-Pro-Rounded-Light.otf';
// import React, { Suspense } from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import './styles.css';
// import App from './App';
// import { RootProvider } from 'providers/RootProvider';
// import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'connected-react-router';
// import configureStore, { history } from './redux/store/store';
// import LoadingApp from 'components/LoadingApp/LoadingApp';
// const AppWrapper = () => {
//   const initStore = configureStore();
//   return (
//     <Provider store={initStore}>
//       <ConnectedRouter history={history}>
//         <Suspense fallback={<LoadingApp />}>
//           <RootProvider>
//             <App />
//           </RootProvider>
//         </Suspense>
//       </ConnectedRouter>
//     </Provider>
//   );
// };
// const bootstrapApplication = () => {
//   ReactDOM.render(<AppWrapper />, document.getElementById('root'));
// };
// bootstrapApplication();

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'fontsource-open-sans';
import './fonts/SFProFont/SFPRODISPLAYBOLD.OTF';
import './fonts/SFProFont/SF-Pro-Rounded-Thin.otf';
import './fonts/SFProFont/SF-Pro-Rounded-Regular.otf';
import './fonts/SFProFont/SF-Pro-Rounded-Light.otf';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles.css';
import App from './App';
import { RootProvider } from 'providers/RootProvider';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './redux/store/store';
import LoadingApp from 'components/LoadingApp/LoadingApp';
import { PublicClientApplication,EventType,EventMessage,AuthenticationResult } from '@azure/msal-browser';
import {msalConfig} from "./authconfig";
import { BrowserRouter } from "react-router-dom";
export const msalInstance = new PublicClientApplication(msalConfig);
 
const accounts = msalInstance.getAllAccounts();
 
console.log(accounts);
 
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}
 
msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    console.log(account, 'account')
 
    msalInstance.setActiveAccount(account);
  }
});
 
msalInstance.initialize().then(() => {
  msalInstance
    .handleRedirectPromise()
 
    .then((authResult) => {
      const account = msalInstance.getActiveAccount();
 
      console.log(account);
 
      if (!account) {
        msalInstance.loginRedirect();
      }
    })
 
    .catch((err) => {
      console.log("An error occurred. Please try again later.");
    });
});
 
 
const AppWrapper = () => {
  const initStore = configureStore();
  return (
    <Provider store={initStore}>
      <ConnectedRouter history={history}>
        <Suspense fallback={<LoadingApp />}>
          <RootProvider>
          <BrowserRouter>
            <App pca={msalInstance}/>
          </BrowserRouter>
          </RootProvider>
        </Suspense>
      </ConnectedRouter>
    </Provider>
  );
};
const bootstrapApplication = () => {
  ReactDOM.render(<AppWrapper />, document.getElementById('root'));
};
bootstrapApplication();
