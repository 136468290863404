import { ACTIONS } from '../../constants/actionConstants';

const apiStatusState: any = {
  createCustomReport: {
    isSuccess: false,
  },
  deleteCustomReport: {
    isSuccess: false,
  },
  updateCustomReport: {
    isSuccess: false,
  },
  createCustomModule: {
    isSuccess: false,
  },
  updateCustomModule: {
    isSuccess: false,
  },
};

export const getApiStatus = (state = apiStatusState, action: any) => {
  switch (action.type) {
    case ACTIONS.SET_API_STATUS:
      return { ...state, ...action.data };
    case ACTIONS.RESET_API_STATUS:
      return apiStatusState;
    default:
      return state;
  }
};
