/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios, { AxiosInstance, AxiosResponse } from 'axios';

const extractErrorAndRethrow = (error: any) => {
  if (Axios.isCancel(error)) {
    // API request is canceled
    return Promise.reject({
      message: error.message,
      isCancelled: true,
    });
  }

  return Promise.reject(error);
};

type ResponseInterceptorMiddlewareArgs = [
  ((value: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>) | undefined,
  ((error: any) => any) | undefined
][];

const RESPONSE_INTERCEPTORS: ResponseInterceptorMiddlewareArgs = [
  [undefined, extractErrorAndRethrow],
];

export default function registerResponseInterceptors(api: AxiosInstance) {
  RESPONSE_INTERCEPTORS.forEach(handlers => api.interceptors.response.use(...handlers));
}
