import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
export const useAppLayoutStyles = (appTheme: any) =>
  makeStyles(
    (theme: Theme) => ({
      root: {
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background.paper,
        height: '100vh',
        overflow: 'hidden',
      },
      viewportContainer: {
        display: 'flex',
        height: `calc(100vh - 10rem)`,
      },
      leftSideNavbarWrapper: {
        background: theme.palette.background.paper,
        boxShadow: theme.shadows[3],
      },
      rightSideContainer: {
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      },
      centerViewWrapper: {
        flex: 1,
        overflowY: 'auto',
        overflowX: 'auto',
        padding: theme.spacing(3),
        background: appTheme.appBodyBackground,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
      },
      centerViewWrapperSelected: {
        flex: 1,
        overflowY: 'auto',
        overflowX: 'auto',
        padding: theme.spacing(3),
        background: appTheme.appBodyBackground,
        alignItems: 'center',
        justifyContent: 'center',
      },
      headerBar: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: theme.shadows[3],
        minHeight: 50,
      },
      navs: {
        fontWeight: 500,
        textDecoration: 'none',

        '&:hover': {
          textDecoration: 'none',
        },
      },
      simpleBarHeight: {
        maxHeight: `calc(100vh - 8rem)`,
      },
    }),
    { name: 'AppLayout' }
  )(appTheme);
