import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { SideBarListingProps } from './SideBarListing.props';
import { useGetClassId, useGetDepartment, useGetStoreId, useGetSubCategory } from 'services';
import './SideBarListing.css';
import { setSubTabsState } from 'redux/action/subTabSelections';
import NavDownIcon from 'images/nav-down-icon.svg';
import ImageSvg from 'helper/SVGComponent';
const SideBarListing: FC<SideBarListingProps> = props => {
  const dispatch = useDispatch();
  let history = useHistory();
  const {
    nav,
    isOpen,
    navIndex,
    values,
    setValues,
    navStyle,
    setSelectedNav,
    selectedNav,
    setOpenAlert,
    isFilterActive,
    setIsOpen,
    onDragStartModule,
    onDragEndModule,
    onDragOverModule,
    onDragStartReport,
    onDragEndReport,
  } = props;

  const [showSubNav, setShowSubNav] = React.useState(false);
  const [toggleDropDown, setToggleDropDown] = React.useState(false);
  const subTabsState = useSelector((state: any) => state.subTabsState);
  const appThemeState = useSelector((state: any) => state.appTheme);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isOpen) {
      setShowSubNav(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedNav?.key === nav?.key) {
      if (showSubNav) {
        setShowSubNav(false);
      } else {
        setShowSubNav(true);
      }
    } else {
      setShowSubNav(false);
    }
  }, [selectedNav, toggleDropDown]);
  const { classIds } = useGetClassId(
    values?.start_year,
    values?.start_month,
    values?.end_year,
    values?.end_month,
    values?.subCategory,
    values?.department
  );
  const { subCategoryOptions } = useGetSubCategory(
    values?.start_year,
    values?.start_month,
    values?.end_year,
    values?.end_month,
    values?.department
  );
  const { departmentOptions } = useGetDepartment(
    values?.start_year,
    values?.start_month,
    values?.end_year,
    values?.end_month
  );
  const { storeIdOptions } = useGetStoreId(
    values?.classId,
    values?.start_year,
    values?.start_month,
    values?.end_year,
    values?.end_month,
    values?.department,
    values?.subCategory
  );
  const onclickNav = (nav: any) => {
    if (
      subTabsState?.selectedSubTabs.filter((subTab: any) => subTab?.key === nav?.key).length === 0
    ) {
      dispatch(setSubTabsState({ selectedSubTabs: [...subTabsState?.selectedSubTabs, nav] }));
    }
  };
  const onclickSubNav = (subNav: any, nav: any) => {
    if (isFilterActive || pathname.includes('createNewReport') || pathname.includes('editReport')) {
      setOpenAlert(true);
    } else {
      if (
        subTabsState?.selectedSubTabs.filter((subTab: any) => subTab?.key === subNav?.key)
          .length === 0
      ) {
        dispatch(setSubTabsState({ selectedSubTabs: [...subTabsState?.selectedSubTabs, subNav] }));
      }
      dispatch(setSubTabsState({ selectedSubTab: subNav }));
      history.push(subNav?.route);
    }
  };
  const showSubnavHandler = (nav: any) => {
    if (isFilterActive || pathname.includes('createNewReport') || pathname.includes('editReport')) {
      setOpenAlert(true);
    } else {
      if (!nav?.subNavs?.length) {
        onclickNav(nav);
        dispatch(setSubTabsState({ selectedSubTab: nav }));
      }
      setSelectedNav(nav);
      setToggleDropDown(!toggleDropDown);
    }
  };
  const handleNavSelection = (nav: any) => {
    if (isFilterActive || pathname.includes('createNewReport') || pathname.includes('editReport')) {
      setOpenAlert(true);
    } else {
      // if (nav?.subNavs[0]) {
      history.push(nav?.route);
      //}
    }
  };

  console.log(nav, nav.icon)

  return (
    <>
      <li
        className={`mt-3 mb3 ${selectedNav?.key === nav?.key ? 'active' : ''}`}
        key={nav?.title}
        onDragOver={e => onDragOverModule(e, navIndex)}
      >
        <div
          data-toggle="collapse"
          aria-expanded={showSubNav}
          className={`nav-border-radius cursor-pointer ${isOpen ? 'dropdown-toggle' : ''}`}
          onClick={() => {
            isOpen && showSubnavHandler(nav);
          }}
          draggable
          onDragStart={e => onDragStartModule(e, navIndex)}
          onDragEnd={onDragEndModule}
        >
          <div
            onClick={() => {
              isOpen && !nav?.subNavs?.length && handleNavSelection(nav);
              !isOpen && setIsOpen();
            }}
            key={nav?.key}
            className={navStyle}
          >
            <div
              className={` ${selectedNav?.key === nav?.key ? 'nav-active-style' : ''} ${
                subTabsState?.selectedSubTab?.key === nav?.key ? 'selected-nav-style' : ''
              }  ${isOpen ? 'nav-items' : 'nav-items-open'}`}
            >
              {/* {nav?.icon === 'gear' ? 
                  <ImageSvg name='gear' />
                : 
              nav?.icon === 'navCategory' ? 
                  <ImageSvg name='navCategory' />
                :  */}
              <ImageSvg key={nav?.icon} name={nav?.icon} apptheme={appThemeState} />
              {/* <img
                className={isOpen ? 'nav-item-icon' : ''}
                src={nav?.icon}
                width="24"
                height={24}
                alt="category-logo"
                style={{ fill: 'red' }}
              /> */}
              {/* } */}
              {isOpen && <div className="nav-list-title-1">{nav?.title}</div>}
              {isOpen && nav?.subNavs?.length ? (
                <div className="nav-item-drop-container">
                  <ImageSvg key='downArrow' name='downArrow' apptheme={appThemeState}/>
                  {/* <img
                    className={isOpen ? 'nav-item-drop' : ''}
                    src={NavDownIcon}
                    width="10"
                    height={10}
                    alt="nav-icon"
                  /> */}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {nav?.subNavs ? (
          <ul
            className={`collapse list-unstyled ${showSubNav ? 'collapse show' : ''}`}
            id="homeSubmenu"
            onDragOver={e => e.preventDefault}
          >
            {isOpen &&
              nav?.subNavs &&
              showSubNav &&
              nav?.subNavs.map((subnav: any, subnavIndex: any) => {
                return (
                  <div style={{ position: 'relative' }}>
                    <li
                      key={subnavIndex}
                      className={`subnavstyle ${
                        subTabsState?.selectedSubTab?.key === subnav?.key
                          ? 'selected-nav-style'
                          : ''
                      }`}
                      onClick={() => {
                        onclickSubNav(subnav, nav);
                      }}
                    >
                      <div
                        className={`nav-list-title-1 `}
                        draggable
                        onDragStart={e => onDragStartReport(e, subnav)}
                        onDragEnd={onDragEndReport}
                      >
                        {subnav?.title}
                      </div>
                    </li>
                  </div>
                );
              })}
          </ul>
        ) : null}
      </li>
    </>
  );
};

export default SideBarListing;
