import { useQuery } from 'react-query';
import { putTestData } from 'services/apiCalls';

function compare(a: any, b: any) {
  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }
  return 0;
}

export default function useGetFeatureImportance(
  start_year: string,
  start_month: string,
  end_year: string = '',
  end_month: string = '',
  class_list: string[] = [],
  store_list: string[] = [],
  dept_list: string[] = [],
  sub_category_list: string[] = []
) {
  const path = `forecast_pre_season/aggregationTotalLevel`;
  const { data, isLoading } = useQuery(
    [
      'getFeatureImportance',
      class_list,
      start_year,
      start_month,
      end_year,
      end_month,
      store_list,
      dept_list,
      sub_category_list,
    ],
    () =>
      putTestData(path, {
        class_list,
        start_year,
        start_month,
        end_year,
        end_month,
        store_list,
        dept_list,
        sub_category_list,
      }),
    {
      enabled:
        !!start_year &&
        !!start_month &&
        !!class_list.length &&
        Array.isArray(store_list) &&
        !!store_list.length,
    }
  );

  let Active_Cases: any = 0;
  let Confirmed_Cases: any = 0;
  let Confirmed_Deaths: any = 0;
  let StringencyIndex: any = 0;
  let per_day_Active_Cases_Rate: any = 0;
  let Easter: any = 0;
  let per_day_Deaths_Rate: any = 0;
  let Closeness_to_EOM: any = 0;
  let FDay: any = 0;
  let Fall: any = 0;
  let IDay: any = 0;
  let Historical_In_Transit_Units: any = 0;
  let In_Transit_Units: any = 0;
  let LDay: any = 0;
  let Lockdown_flag: any = 0;
  let Mday: any = 0;
  let New_Year: any = 0;
  const yAxisData: any = [];
  const xAxisData: any = [];
  const series: any = [];

  if (Array.isArray(data) && data.length) {
    data.forEach((item: any) => {
      Active_Cases = Active_Cases + item.Active_Cases;
      Confirmed_Cases = Confirmed_Cases + item.Confirmed_Cases;
      Confirmed_Deaths = Confirmed_Deaths + item.Confirmed_Deaths;
      StringencyIndex = StringencyIndex + item.StringencyIndex;
      per_day_Active_Cases_Rate = per_day_Active_Cases_Rate + item.per_day_Active_Cases_Rate;
      per_day_Deaths_Rate = per_day_Deaths_Rate + item.per_day_Deaths_Rate;
      Closeness_to_EOM = Closeness_to_EOM + item.Closeness_to_EOM;
      Easter = Easter + item.Easter;
      FDay = FDay + item.month;
      Fall = Fall + item.Fall;
      IDay = IDay + item.IDay;
      In_Transit_Units = In_Transit_Units + item.Sales_lag_6;
      Historical_In_Transit_Units = Historical_In_Transit_Units + item.Sales_lag_12;
      LDay = LDay + item?.days_from_christmas;
      Lockdown_flag = Lockdown_flag + item.Lockdown_flag;
      Mday = Mday + item?.seasonal_index;
      New_Year = New_Year + item.days_from_new_year;
    });

    Active_Cases = Active_Cases / data.length;
    Confirmed_Cases = Confirmed_Cases / data.length;
    Confirmed_Deaths = Confirmed_Deaths / data.length;
    StringencyIndex = StringencyIndex / data.length;
    per_day_Active_Cases_Rate = per_day_Active_Cases_Rate / data.length;
    per_day_Deaths_Rate = per_day_Deaths_Rate / data.length;
    Easter = Easter / data.length;
    FDay = FDay / data.length;
    Fall = Fall / data.length;
    IDay = IDay / data.length;
    In_Transit_Units = In_Transit_Units / data.length;
    Historical_In_Transit_Units = Historical_In_Transit_Units / data.length;
    LDay = LDay / data.length;
    Lockdown_flag = Lockdown_flag / data.length;
    Mday = Mday / data.length;
    New_Year = New_Year / data.length;

    series.push({ label: 'Active cases', value: Active_Cases.toFixed(3) });
    series.push({ label: 'Confirmed cases', value: Confirmed_Cases.toFixed(3) });
    series.push({ label: 'Confirmed deaths', value: Confirmed_Deaths.toFixed(3) });
    series.push({ label: 'StringencyIndex', value: StringencyIndex.toFixed(3) });
    series.push({
      label: 'Per day Active Cases Rate',
      value: per_day_Active_Cases_Rate.toFixed(3),
    });
    series.push({ label: 'Per day Deaths Rate', value: per_day_Deaths_Rate.toFixed(3) });
    series.push({ label: 'Easter', value: Easter.toFixed(3) });
    series.push({ label: 'month', value: FDay.toFixed(3) });
    series.push({ label: 'Fall', value: Fall.toFixed(3) });
    series.push({ label: 'Sales Lag 6', value: In_Transit_Units.toFixed(3) });
    series.push({
      label: 'Sales Lag 12',
      value: Historical_In_Transit_Units.toFixed(3),
    });
    series.push({ label: 'Days from Christmas', value: LDay.toFixed(3) });
    series.push({ label: 'Lockdown flag', value: Lockdown_flag.toFixed(3) });
    series.push({ label: 'Seasonal Index', value: Mday.toFixed(3) });
    series.push({ label: 'Days from New Year', value: New_Year.toFixed(3) });

    series.sort(compare);
  }

  if (Array.isArray(series)) {
    series.forEach((element: any) => {
      yAxisData.push(element.label);
      xAxisData.push(element.value);
    });
  }
  return {
    yAxisData,
    xAxisData,
    featureImportanceLoader: isLoading,
  };
}
