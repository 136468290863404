import { useQuery } from 'react-query';
import { putTestData } from 'services/apiCalls';

export default function useGetDepartment(
  start_year: string,
  start_month: string,
  end_year: string = '',
  end_month: string = ''
) {
  const path = `filters/getDepartment`;
  const { data, isLoading } = useQuery(
    ['getDepartment', start_year, start_month, end_year, end_month],
    () => putTestData(path, { start_year, start_month, end_year, end_month }),
    { enabled: !!start_year && !!start_month }
  );
  //dropdown
  const departmentOptions: any[] = [];
  if (Array.isArray(data) && data.length) {
    data.forEach((item: any) => {
      departmentOptions.push({ label: item.department, value: item.department });
    });
  }
  return { departmentOptions, isLoading };
}
