import { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import AccentureIcon from 'images/acc.png';
import './LoginPage.css';
import TextInputField from 'components/TextInputField/TextInputField';
import { useSelector } from 'react-redux';
import AlertDialog from 'components/AlertDialog';
import { Alert } from '@mui/material';
import ImageSvg from 'helper/SVGComponent';
import { dataUrlToImage } from 'utils/util';
import { TextField } from '@mui/material';
import LoginBackground from 'images/login-background.svg';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const LoginPage = () => {
  const [username, setUsername] = useState<any>('');
  const [password, setPassword] = useState<any>('');
  const [openAlert, setOpenAlert] = useState(false);
  const [appLogo, setAppLogo] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const appThemeState = useSelector((state: any) => state.appTheme);
  const history = useHistory();
  useEffect(() => {
    async function getImage(url: any, name: any) {
      let { image } = await dataUrlToImage(url, name);
      setAppLogo(image);
    }
    getImage(appThemeState?.appLogo, 'appLogo');
  }, [appThemeState]);

  async function fetchCSRFToken() {
    try {
      const response = await fetch('https://ai-retail-localization-assortment-harvest-api-uat.azurewebsites.net/get_csrf_token', {
        method: 'POST'
      });
      if (response.ok) {
        const { csrf_token } = await response.json();
        return csrf_token;
      } else {
        throw new Error('Failed to fetch CSRF token');
      }
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
      throw error;
    }
  }

    const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const csrfToken = await fetchCSRFToken();
     
      const response = await fetch('https://ai-retail-localization-assortment-harvest-api-uat.azurewebsites.net/get_user_credentials', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        // Check the API response
        if (data === true) {
          // Successful login
          sessionStorage.setItem('userId', JSON.stringify({ username:'username', password:'password' }));
          // Cookies.set('userId', JSON.stringify({ username: 'username', password: 'password' }));
          history.push('/');
        } else {
          // Invalid credentials
          setOpenAlert(true);
        }
      } else {
        // Handle non-OK response
        setOpenAlert(true);
      }
    } catch (error) {
      console.error('Error during API call:', error);
      setOpenAlert(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setUsername('');
    setPassword('');
  };

  useEffect(() => {
    async function getImage(url: any, name: any) {
      let { image } = await dataUrlToImage(url, name);
      setAppLogo(image);
    }
    getImage(appThemeState?.appLogo, 'appLogo');
  }, [appThemeState]);

  return (
    <div style={{ display: 'flex' }}>
      <div className="login-background">
        <img src={LoginBackground} alt='login-background' width='100%' />
        <h1 style={{ width: '30rem', fontWeight: '400', fontSize:'4.5rem', lineHeight: '5.3rem', letterSpacing: '-1.5%', position: 'absolute' }}>Welcome to Supply Chain Analytic Platform</h1>
      </div>
      <div style={{ width: '42%', height: '100vh', background: '#1E1E1E', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="login-page-profile-card">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h3 style={{ color: 'white', fontWeight: '600', fontSize: '2.5rem', marginBottom: '0.5rem' }}>Log In</h3>
          </div>
          <div className="login-fields" style={{ color: 'white' }}>
            <div style={{ marginTop: '2rem' }}>
              <div>
                <span style={{ fontSize: '1rem', color: '#FFFFFF' }}>Username</span>
                <div style={{ marginTop: '6px' }}>
                  <TextField
                    value={username}
                    onChange={(e: any) => setUsername(e.target.value)}
                    label='Username'
                    id="outlined-size-small"
                    size="small"
                    type='text'
                    fullWidth={true}
                    sx={{
                      '&.Mui-focused .MuiIconButton-root': { color: '#A8B1B8 !important' },
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#A8B1B8 !important',
                        width: '100%',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#A8B1B8 !important',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#A8B1B8 !important',
                      },
                      '.MuiSvgIcon-root ': {
                        fill: `#A8B1B8 !important`,
                        fontSize: 'small',
                      },
                      fontSize: '1rem', // font size of selected value
                      fontFamily: 'SFProRoundedRegular !important',
                      '.css-1xqu88-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                        { borderColor: '#A8B1B8 !important' },
                      '.css-1xqu88-MuiInputBase-root-MuiOutlinedInput-root': {
                        fontSize: '1rem !important',
                        color: '#A8B1B8 !important'
                      },
                      '.css-1nftsb0-MuiFormLabel-root-MuiInputLabel-root': {
                        fontSize: '1rem !important',
                        color: '#A8B1B8 !important'
                      },
                      '.css-1l0q85s-MuiFormLabel-root-MuiInputLabel-root': {
                        fontSize: '1rem !important',
                        color: '#A8B1B8 !important',
                      },
                      '.css-1w38l0a-MuiInputBase-root-MuiOutlinedInput-root': {
                        fontSize: '1rem !important',
                        color: '#A8B1B8 !important',
                      },
                      '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                        fontSize: '1rem !important',
                        color: '#A8B1B8 !important',
                      },
                      '.css-pq5sy6.Mui-focused': {
                        color: '#A8B1B8 !important',
                      },
                      '.css-1o6z5ng': {
                        fontSize: '1rem !important',
                        color: '#A8B1B8 !important',
                      },
                      '.css-1qk3djn': {
                        fontSize: '1rem !important',
                        color: '#A8B1B8 !important',
                      },
                      '.css-pq5sy6': {
                        color: '#A8B1B8 !important',
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div style={{ marginTop: '1.5rem' }}>
                <span style={{ fontSize: '1rem', color: '#FFFFFF' }}>Password</span>
                <div style={{ marginTop: '6px' }}>
                  <TextField
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                    label='Password'
                    id="outlined-size-small"
                    size="small"
                    type='password'
                    fullWidth={true}
                    sx={{
                      '&.Mui-focused .MuiIconButton-root': { color: '#A8B1B8 !important' },
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#A8B1B8 !important',
                        width: '100%',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#A8B1B8 !important',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#A8B1B8 !important',
                      },
                      '.MuiSvgIcon-root ': {
                        fill: `#A8B1B8 !important`,
                        fontSize: 'small',
                      },
                      fontSize: '1rem', // font size of selected value
                      fontFamily: 'SFProRoundedRegular !important',
                      '.css-1xqu88-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                        { borderColor: '#A8B1B8 !important' },
                      '.css-1xqu88-MuiInputBase-root-MuiOutlinedInput-root': {
                        fontSize: '1rem !important',
                        color: '#A8B1B8 !important'
                      },
                      '.css-1nftsb0-MuiFormLabel-root-MuiInputLabel-root': {
                        fontSize: '1rem !important',
                        color: '#A8B1B8 !important'
                      },
                      '.css-1l0q85s-MuiFormLabel-root-MuiInputLabel-root': {
                        fontSize: '1rem !important',
                        color: '#A8B1B8 !important',
                      },
                      '.css-1w38l0a-MuiInputBase-root-MuiOutlinedInput-root': {
                        fontSize: '1rem !important',
                        color: '#A8B1B8 !important',
                      },
                      '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                        fontSize: '1rem !important',
                        color: '#A8B1B8 !important',
                      },
                      '.css-pq5sy6.Mui-focused': {
                        color: '#A8B1B8 !important',
                      },
                      '.css-1o6z5ng': {
                        fontSize: '1rem !important',
                        color: '#A8B1B8 !important',
                      },
                      '.css-1qk3djn': {
                        fontSize: '1rem !important',
                        color: '#A8B1B8 !important',
                      },
                      '.css-pq5sy6': {
                        color: '#A8B1B8 !important',
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="button-submit-login">
            <p style={{ color: '#B9CDFF', fontSize: '1rem', textDecoration: 'underline', cursor: 'pointer' }}>Forgot password?</p>
            <Button
              onClick={handleSubmit}
              variant="contained"
              type="submit"
              style={{
                backgroundColor: '#386efa',
                fontFamily: 'SFProRoundedRegular',
                width: '9.56rem',
                padding: '0.625rem 2rem',
                marginTop: '1rem',
                fontSize: '1rem',
                borderRadius: '8px'
              }}
            >
              Sign In
            </Button>
          </div>
        </div>
      </div>
      <AlertDialog
        appTheme={appThemeState}
        open={openAlert}
        message={<Alert severity="error">Invalid username or passowrd</Alert>}
        onConfirm={() => setOpenAlert(false)}
      />
    </div>
  );
};

export default LoginPage;

