import { Backdrop, CircularProgress } from '@mui/material';
import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import RequireAuth from 'components/AuthComponent/AuthComponent';
const demandForcast = lazy(() => import('views/DemandForcast'));
const preSeason = lazy(() => import('views/PreSeasonPlanning'));
const forecastPerformance = lazy(() => import('views/ForecastPerformance'));
const salesOverview = lazy(() => import('views/SalesOverview'));
const LoadingPage = lazy(() => import('views/LoadingPage/LoadingPage'));
const landingPage = lazy(() => import('views/LandingPage/LandingPage'));
const CustomizationModuleWrapper = lazy(
  () => import('views/CustomizationModuleWrapper/CustomizationModuleWrapper')
);
const CreateReport = lazy(() => import('views/CreateReport/CreateReport'));
const CustomReportsWrapper = lazy(() => import('views/CustomReportsWrapper/CustomReportsWrapper'));
const ConfigurationWrapper = lazy(() => import('views/ConfigurationWrapper/ConfigurationWrapper'));
const EmptyStateModule = lazy(
  () => import('views/CustomizationModuleWrapper/Components/EmptyStateModule')
);
const LoginPage = lazy(() => import('views/LoginPage'));
const ControlTowerWrapper = lazy(() => import('views/ControlTowerWrapper/ControlTowerWrapper'));
const AppRoutes = () => {
  return (
    <Suspense
      fallback={
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      }
    >
      <Switch>
        <Route
          exact
          path={`/demandPlanning/preSeason`}
          render={(props: any) => <RequireAuth Component={preSeason} {...props} />}
        />

        <Route
          exact
          path={`/demandPlanning/inSeason`}
          render={(props: any) => <RequireAuth Component={LoadingPage} {...props} />}
        />

        <Route
          exact
          path={`/demandPlanning/allocation`}
          render={(props: any) => <RequireAuth Component={demandForcast} {...props} />}
        />

        <Route
          exact
          path={`/demandPlanning/salesOverview`}
          render={(props: any) => <RequireAuth Component={salesOverview} {...props} />}
        />

        <Route
          exact
          path={`/demandPlanning/forecastPerformance`}
          render={(props: any) => <RequireAuth Component={forecastPerformance} {...props} />}
        />
        <Route
          exact
          path={`/demandPlanning/forecastvsactual`}
          render={(props: any) => <RequireAuth Component={LoadingPage} {...props} />}
        />
        <Route
          exact
          path={`/demandPlanning/forecastDrivers`}
          render={(props: any) => <RequireAuth Component={LoadingPage} {...props} />}
        />

        <Route
          exact
          path={`/demandPlanning/forecastDecompose`}
          render={(props: any) => <RequireAuth Component={LoadingPage} {...props} />}
        />

        <Route
          exact
          path={`/demandPlanning/customizeModule`}
          render={(props: any) => <RequireAuth Component={CustomizationModuleWrapper} {...props} />}
        />

        <Route
          exact
          path={`/demandPlanning/customModule/:moduleTag/editReport/:title`}
          render={(props: any) => <RequireAuth Component={CreateReport} {...props} />}
        />

        <Route
          exact
          path={`/demandPlanning/customModule/:title`}
          render={(props: any) => <RequireAuth Component={EmptyStateModule} {...props} />}
        />

        <Route
          exact
          path={`/`}
          render={(props: any) => <RequireAuth Component={landingPage} {...props} />}
        />
        <Route
          exact
          path={`/demandPlanning/customReport/:customReportName`}
          render={(props: any) => <RequireAuth Component={CustomReportsWrapper} {...props} />}
        />

        <Route
          exact
          path={`/demandPlanning/configuration`}
          render={(props: any) => <RequireAuth Component={ConfigurationWrapper} {...props} />}
        />
        <Route path="/login" component={LoginPage} />
        <Route
          path="/controlTower/:moduleType"
          render={(props: any) => <RequireAuth Component={ControlTowerWrapper} {...props} />}
        />
        <Route>
          <Redirect to={`/`} />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
