import { useQuery } from 'react-query';
import { putTestData } from 'services/apiCalls';

export default function useGetDistinctFeatures(
  class_list: string[],
  start_year: string,
  start_month: string,
  end_year: string = '',
  end_month: string = '',
  store_list: string[] = [],
  dept_list: string[] = [],
  sub_category_list: string[] = [],
  flag: boolean = false
) {
  const path = `first_allocation/getDistinctFeatures`;
  const { data, isLoading } = useQuery(
    [
      'getDistinctFeatures',
      class_list,
      start_year,
      start_month,
      end_year,
      end_month,
      store_list,
      dept_list,
      sub_category_list,
      flag,
    ],
    () =>
      putTestData(path, {
        class_list,
        start_year,
        start_month,
        end_year,
        end_month,
        store_list,
        dept_list,
        sub_category_list,
        flag,
      }),
    {
      enabled:
        !!start_year &&
        !!start_month &&
        !!class_list.length &&
        Array.isArray(store_list) &&
        !!store_list.length,
    }
  );

  return { distinctRows: data, isLoading };
}
