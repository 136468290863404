import { ACTIONS } from '../../constants/actionConstants';

export const setSubTabsState = (data: any) => {
  return {
    type: ACTIONS.SET_SUB_TAB_STATE,
    data,
  };
};

export const resetSubTabsState = (data: any) => {
  return {
    type: ACTIONS.RESET_SUB_TAB_STATE,
    data,
  };
};
