import { ACTIONS } from '../../constants/actionConstants';

export const setApiStatus = (data: any) => {
  return {
    type: ACTIONS.SET_API_STATUS,
    data,
  };
};

export const resetApiStatus = (data: any) => {
  return {
    type: ACTIONS.RESET_API_STATUS,
    data,
  };
};
