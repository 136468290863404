import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SideBarMenuProps } from './SideBarMenu.props';
import SideBarListing from './SideBarListing/SideBarListing';
import {
  useGetClassId,
  useGetDepartment,
  useGetStoreId,
  useGetSubCategory,
  useGetCustomReports,
  useUpdateCustomReport,
  useGetModules,
} from 'services';
import { SideBarData } from 'constants/SideBarData';
import { setSubTabsState } from 'redux/action/subTabSelections';
import { setApiStatus } from 'redux/action/apiStatus';
import customReportIcon from 'images/nav-icon-preseason.svg';
import { Formik, useFormikContext } from 'formik';
import './SideBarMenu.css';
const SideBarMenu: FC<SideBarMenuProps> = props => {
  const { isOpen } = props;
  const dispatch = useDispatch();
  let history = useHistory();
  const { values, setValues } = useFormikContext<any>();
  const [mergedSideBarData, setMergedSideBarData] = useState<any>(SideBarData);
  const [selectedNav, setSelectedNav] = useState(mergedSideBarData?.navs?.[0].subNavs?.[0]);
  const subTabsState = useSelector((state: any) => state.subTabsState);
  const apiStatus = useSelector((state: any) => state.APIStatus);
  const [draggedModule, setDraggedModule] = useState<any>(null);
  const [draggedReport, setDraggedReport] = useState<any>(null);
  const [draggedReportUpdate, setDraggedReportUpdate] = useState<any>(null);
  const {
    data: moduleData,
    isLoading: isLoadingModules,
    isSuccess,
    refetch: modulesRefetch,
  } = useGetModules();
  const { customReports, isLoading, customReportsSuccess, refetch, fetchStatus }: any =
    useGetCustomReports(
      apiStatus?.createCustomReport?.isSuccess,
      apiStatus?.deleteCustomReport?.isSuccess
    );
  const {
    mutate: mutateUpdate,
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
    data: dataUpdate,
  } = useUpdateCustomReport();
  const [customModuleMerge, setCustomModuleMerge] = useState<any>(SideBarData);
  useEffect(() => {
    if (moduleData) {
      const modules = moduleData.map((module: any) => {
        return {
          title: module.Module_Name,
          subTabTitle: module.Module_Name,
          route: `/demandPlanning/customModule/${module.Module_Name}`,
          icon: module.Icon,
          key: module.Module_Name,
          isFilter: false,
          isNav: true,
          subNavs: [],
        };
      });
      const navsMerged = [...SideBarData.navs, ...modules];
      navsMerged.map((elem, index) => {
        if (elem.title === 'Customize module') {
          navsMerged.splice(index, 1);
          navsMerged.push(elem);
        }
      });
      navsMerged.map((elem, index) => {
        if (elem.title === 'Configuration') {
          navsMerged.splice(index, 1);
          navsMerged.push(elem);
        }
      });
      setCustomModuleMerge({ ...SideBarData, navs: navsMerged });
    }
  }, [moduleData]);

  useEffect(() => {
    refetch();
  }, [apiStatus?.createCustomReport, apiStatus?.updateCustomReport, apiStatus?.deleteCustomReport]);
  useEffect(() => {
    modulesRefetch();
  }, [apiStatus?.createCustomModule, apiStatus?.updateCustomModule]);
  useEffect(() => {
    isSuccessUpdate &&
      dispatch(setApiStatus({ updateCustomReport: { isSuccess: isSuccessUpdate } }));
  }, [isSuccessUpdate]);
  useEffect(() => {
    if (
      (subTabsState && subTabsState?.selectedSubTabs?.length === 0) ||
      subTabsState?.selectedSubTab === null
    ) {
      dispatch(
        setSubTabsState({
          selectedSubTabs: [mergedSideBarData?.navs[0].subNavs?.[0]],
          selectedSubTab: mergedSideBarData?.navs?.[0].subNavs?.[0],
        })
      );
    }
    history.push(subTabsState?.selectedSubTab?.route);
  }, [subTabsState?.selectedSubTabs, subTabsState?.selectedSubTab]);

  const mergeReportsToSideBar = (reports: any) => {
    let newSideBarData = JSON.parse(JSON.stringify(customModuleMerge));
    if (reports?.length && newSideBarData) {
      reports.forEach((report: any) => {
        newSideBarData?.navs?.forEach((nav: any) => {
          if (
            report?.moduleTag === nav?.key &&
            !nav?.subNavs?.filter((subNav: any) => subNav?.key === report.reportName).length
          ) {
            let newSubNav: any = {
              reportId: report.id,
              title: report?.reportName,
              subTabTitle: `${nav?.title} > ${report?.reportName}`,
              icon: customReportIcon,
              route: `/demandPlanning/customReport/${report?.reportName}`,
              key: report?.reportName,
              isFilter: false,
              isCustomReport: true,
            };
            nav?.subNavs?.push(newSubNav);
          }
        });
      });
      setMergedSideBarData(newSideBarData);
    }
  };

  useEffect(() => {
    if (customReports && (customReportsSuccess || fetchStatus)) {
      mergeReportsToSideBar(customReports);
    }
  }, [customReportsSuccess, fetchStatus, customModuleMerge]);
  useGetClassId(
    values?.start_year,
    values?.start_month,
    values?.end_year,
    values?.end_month,
    values?.subCategory,
    values?.department
  );
  useGetStoreId(
    values?.classId,
    values?.start_year,
    values?.start_month,
    values?.end_year,
    values?.end_month,
    values?.department,
    values?.subCategory
  );
  useGetSubCategory(
    values?.start_year,
    values?.start_month,
    values?.end_year,
    values?.end_month,
    values?.department
  );

  useGetDepartment(values?.start_year, values?.start_month, values?.end_year, values?.end_month);
  const onDragStartModule = (e: any, index: any) => {
    setDraggedModule(mergedSideBarData?.navs[index]);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };
  const onDragOverModule = (e: any, index: any) => {
    e.preventDefault();
    const draggedOverModule = mergedSideBarData?.navs[index];
    if (draggedModule) {
      if (draggedModule === draggedOverModule) {
        return;
      }
      let items = mergedSideBarData?.navs?.filter((item: any) => item !== draggedModule);
      items.splice(index, 0, draggedModule);
      let newSideBarData = { ...mergedSideBarData, navs: items };
      setMergedSideBarData(newSideBarData);
    }
    if (draggedReport) {
      let reportToUpdate =
        customReports?.filter((report: any) => report?.reportName === draggedReport?.title)[0] ||
        null;

      if (!reportToUpdate || reportToUpdate?.moduleTag === draggedOverModule?.key) {
        setDraggedReportUpdate(null);
        return;
      }
      if (reportToUpdate) {
        const updateReport: any = {
          reportName: reportToUpdate?.reportName,
          config: {
            moduleTag: draggedOverModule?.key,
            layoutType: reportToUpdate?.layoutType,
            customLayout: reportToUpdate?.customLayout,
            charts: reportToUpdate?.charts,
          },
        };
        setDraggedReportUpdate(updateReport);
      }
    }
  };
  const onDragEndModule = () => {
    setDraggedModule(null);
  };
  const onDragStartReport = (e: any, subNav: any) => {
    setDraggedReport(subNav);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  const onDragEndReport = () => {
    draggedReportUpdate && mutateUpdate(draggedReportUpdate);
    setDraggedReport(null);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={values}
        onSubmit={(values: any) => {
          setValues(values);
        }}
      >
        {({ submitForm, values }) => {
          return (
            <>
              <div className="nav-items-container">
                <ul
                  className="list-unstyled components mb-4 nav-item-ul mt-3"
                  onDragOver={e => e.preventDefault}
                >
                  {mergedSideBarData &&
                    mergedSideBarData?.navs &&
                    mergedSideBarData?.navs.map((nav: any, navIndex: any) => {
                      return (
                        <SideBarListing
                          nav={nav}
                          key={nav?.key}
                          navIndex={navIndex}
                          values={values}
                          setValues={setValues}
                          navStyle={props?.navs}
                          setSelectedNav={setSelectedNav}
                          selectedNav={selectedNav}
                          {...props}
                          onDragStartModule={onDragStartModule}
                          onDragEndModule={onDragEndModule}
                          onDragOverModule={onDragOverModule}
                          onDragStartReport={onDragStartReport}
                          onDragEndReport={onDragEndReport}
                        />
                      );
                    })}
                </ul>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default SideBarMenu;
