export const ACTIONS = {
  SET_SUB_TAB_STATE: 'SET_SUB_TAB_STATE',
  RESET_SUB_TAB_STATE: 'RESET_SUB_TAB_STATE',

  SET_FILTER_STATE: 'SET_FILTER_STATE',
  SET_API_STATUS: 'SET_API_STATUS',
  RESET_API_STATUS: 'RESET_API_STATUS',
  SET_CONFIG_STATUS: 'SET_CONFIG_STATUS',
  RESET_CONFIG_STATUS: 'RESET_CONFIG_STATUS',
  SET_APP_THEME: 'SET_APP_THEME',
  RESET_APP_THEME: 'RESET_APP_THEME',
  SET_CUSTOM_MODULE_STATUS: 'SET_CUSTOM_MODULE_STATUS',
  RESET_CUSTOM_MODULE_STATUS: 'RESET_CUSTOM_MODULE_STATUS',
  SET_APP_THEME_UPDATE: 'SET_APP_THEME_UPDATE',
};
