import EditRoundedIcon from '@mui/icons-material/EditRounded';

const CustomHeader = (props: any) => {
  return (
    <div className="ag-header-group-cell-label">
      {props.displayName} &nbsp;
      {props.editable && <EditRoundedIcon fontSize="small" />}
    </div>
  );
};

export default CustomHeader;
