import { useQuery } from 'react-query';
import { putTestData } from 'services/apiCalls';

export default function useGetClassId(
  start_year: string,
  start_month: string,
  end_year: string = '',
  end_month: string = '',
  sub_category_list: string[] = [],
  dept_list: string[] = []
) {
  const path = `filters/getClassId`;
  const { data, isLoading } = useQuery(
    ['classId', start_year, start_month, end_year, end_month, dept_list, sub_category_list],
    () =>
      putTestData(path, {
        start_year,
        start_month,
        end_year,
        end_month,
        dept_list,
        sub_category_list,
      }),
    {
      enabled:
        !!start_year &&
        !!start_month &&
        Array.isArray(sub_category_list) &&
        !!sub_category_list.length,
    }
  );
  const classIds: any[] = [];
  if (Array.isArray(data) && data.length) {
    data.forEach((item: any) => {
      classIds.push({ label: item.CL_DESC, value: item.CL_ID });
    });
  }
  return { classIds, isLoading };
}
