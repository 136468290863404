import React from 'react';
import { Button, Drawer, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useStyles } from '../../DemandForcast.styles';
import { FlexItemSpacer } from 'components/FlexItemSpacer';
import Filters from '../Filters';
import { useGetClassId, useGetDepartment, useGetStoreId, useGetSubCategory } from 'services';
import { Formik, useFormikContext } from 'formik';
import EditIcon from 'images/edit-icon.svg';
import { setSubTabsState } from 'redux/action/subTabSelections';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

type Props = {
  children: JSX.Element;
  title: string;
  edit?: boolean;
  currentReport?: any;
};

const Wrapper = ({ children, title, edit, currentReport }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const subTabsState = useSelector((state: any) => state.subTabsState);
  const appThemeState = useSelector((state: any) => state.appTheme);
  const dispatch = useDispatch();
  let history = useHistory();

  const { values, setValues } = useFormikContext<any>();
  useGetClassId(
    values?.start_year,
    values?.start_month,
    values?.end_year,
    values?.end_month,
    values?.subCategory,
    values?.department
  );
  useGetStoreId(
    values?.classId,
    values?.start_year,
    values?.start_month,
    values?.end_year,
    values?.end_month,
    values?.department,
    values?.subCategory
  );
  useGetSubCategory(
    values?.start_year,
    values?.start_month,
    values?.end_year,
    values?.end_month,
    values?.department
  );
  useGetDepartment(values?.start_year, values?.start_month, values?.end_year, values?.end_month);

  const handlerEditReport = () => {
    const subNavEdit = {
      icon: '',
      isCustomReport: true,
      isFilter: false,
      key: `${currentReport.reportName}-edit`,
      reportId: currentReport.reportName,
      title: currentReport.reportName,
      route: `/demandPlanning/customModule/${currentReport.moduleTag}/editReport/${currentReport.reportName}`,
      subTabTitle: `${currentReport.reportName} > Edit`,
    };
    if (
      subTabsState?.selectedSubTabs.filter((subTab: any) => subTab?.key === subNavEdit?.key)
        .length === 0
    ) {
      dispatch(
        setSubTabsState({ selectedSubTabs: [...subTabsState?.selectedSubTabs, subNavEdit] })
      );
    }
    dispatch(setSubTabsState({ selectedSubTab: subNavEdit }));
    history.push(
      `/demandPlanning/customModule/${currentReport.moduleTag}/editReport/${subNavEdit.title}`
    );
  };

  return (
    <div className={classes.wrapper}>
      <Grid container>
        <Typography variant="h6" className="report-title-style">
          {title}
          {edit ? (
            <Button
              className="edit-button-report"
              style={{ border: `1px solid ${appThemeState?.buttonPrimary}` }}
              onClick={handlerEditReport}
            >
              Edit
              <img className={'edit-icon-report'} src={EditIcon} alt="icon" />
            </Button>
          ) : null}
        </Typography>
        <FlexItemSpacer />
        <Drawer
          anchor="right"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <Formik
            initialValues={values}
            onSubmit={(values: any) => {
              console.log('submitted values in wrapper', values);
              setValues(values);
              setOpen(false);
            }}
          >
            {({ submitForm, values }) => {
              console.log('values ****2', values);
              return (
                <Grid className={classes.drawerWrapper} container spacing={2}>
                  <Filters
                    values={values}
                    setOpen={setOpen}
                    submitForm={submitForm}
                    appTheme={appThemeState}
                  />
                </Grid>
              );
            }}
          </Formik>
        </Drawer>
      </Grid>
      {children}
    </div>
  );
};

export default Wrapper;
