import { ACTIONS } from '../../constants/actionConstants';
import { defaultTheme } from 'constants/defaultAppTheme';
const appThemeState: any = defaultTheme;
const updateAppThemeState: any = {
  appThemeToEdit: null,
};
export const getAppTheme = (state = appThemeState, action: any) => {
  switch (action.type) {
    case ACTIONS.SET_APP_THEME:
      return { ...state, ...action.data };
    case ACTIONS.RESET_APP_THEME:
      return appThemeState;

    default:
      return state;
  }
};
export const getAppThemeToUpdate = (state = updateAppThemeState, action: any) => {
  switch (action.type) {
    case ACTIONS.SET_APP_THEME_UPDATE:
      return { ...updateAppThemeState, ...action.data };
    default:
      return updateAppThemeState;
  }
};
