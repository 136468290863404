import { useMutation } from 'react-query';
import { putTestData } from 'services/apiCalls';

export default function useSavePreSeasonData() {
  const path = `forecast_pre_season/AdjustedForecastTableUpdate`;
  const { data, mutate, isLoading } = useMutation(adjusted_inventory_records =>
    putTestData(path, { adjusted_inventory_records })
  );

  return {
    data,
    mutate,
    isLoading,
  };
}
