import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles: any = makeStyles(
  (theme: Theme) => ({
    wrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto 1fr',
      gridGap: theme.spacing(2),
    },
    drawerWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: 420,
      padding: theme.spacing(2),
    },
    contentGrid: {
      width: '100%',
    },
  }),
  { name: 'Forecast' }
);
