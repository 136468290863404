import React, { FC, useEffect } from 'react';
import { useGetClassId, useGetDepartment, useGetStoreId, useGetSubCategory } from 'services';
import { MultiCheckboxField, SelectField } from 'components/form-fields';
import { months, years } from 'views/DemandForcast/utils';
import { Grid, CircularProgress } from '@mui/material';
import { Field, useFormikContext } from 'formik';

import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

import SimpleBarReact from 'simplebar-react';
import 'simplebar/src/simplebar.css';

// import DatePicker from 'react-date-picker';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { start } from 'repl';
import { useSelector } from 'react-redux';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

interface Props {
  name: any;
  title: any;
  values: any;
}

const AccordionContent: React.FC<Props> = ({ name, title, values }) => {
  const [searchText, setSearchText] = React.useState('');
  const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs('2022-02'));
  const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs('2022-09'));
  const appThemeState = useSelector((state: any) => state.appTheme);

  const formikProps = useFormikContext();

  React.useEffect(() => {
    if (name === 'start_month') {
      const month = startDate?.month();
      formikProps.setFieldValue('start_month', months[month ? month : 0].value, true);
      formikProps.setFieldValue('start_year', `${startDate?.year()}`, true);
    }
  }, [startDate]);

  React.useEffect(() => {
    if (name === 'end_month') {
      const month = startDate?.month();
      formikProps.setFieldValue('end_month', months[month ? month : 1].value, true);
      formikProps.setFieldValue('end_year', `${startDate?.year()}`, true);
    }
  }, [endDate]);

  const handleChange = (e: any) => {
    setSearchText(e.target.value);
  };

  const { classIds } = useGetClassId(
    values?.start_year,
    values?.start_month,
    values?.end_year,
    values?.end_month,
    values?.subCategory,
    values?.department
  );
  const { subCategoryOptions } = useGetSubCategory(
    values?.start_year,
    values?.start_month,
    values?.end_year,
    values?.end_month,
    values?.department
  );
  const { departmentOptions, isLoading } = useGetDepartment(
    values?.start_year,
    values?.start_month,
    values?.end_year,
    values?.end_month
  );
  const { storeIdOptions } = useGetStoreId(
    values?.classId,
    values?.start_year,
    values?.start_month,
    values?.end_year,
    values?.end_month,
    values?.department,
    values?.subCategory
  );

  const fieldSelector = (name: string, title: string, searchText: any) => {
    let component: any = null;
    let options: any = null;
    if (name === 'start_month' || name === 'end_month') {
      component = SelectField;
      options = months;
    }
    if (name === 'start_year' || name === 'end_year') {
      component = SelectField;
      options = years;
    }
    if (name === 'department') {
      component = MultiCheckboxField;
      options = departmentOptions;
    }
    if (name === 'subCategory') {
      component = MultiCheckboxField;
      options = subCategoryOptions;
    }
    if (name === 'classId') {
      component = MultiCheckboxField;
      options = classIds;
    }
    if (name === 'storeId') {
      component = MultiCheckboxField;
      options = storeIdOptions;
    }

    if (searchText !== '') {
      options = options.filter((option: any) =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return (
      <Grid item md={12}>
        {isLoading && name !== 'end_month' && name !== 'start_month' ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        ) : name === 'start_year' ||
          name === 'end_year' ||
          name === 'start_month' ||
          name === 'end_month' ? (
          <div id="select-field" key={name}>
            <div className="title-timeline">{title}</div>
            {/* <Field name={name} label={title} component={component} options={options} fullWidth /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={['year', 'month']}
                label="Year and Month"
                minDate={dayjs('2019-01-01')}
                maxDate={dayjs('2022-12-01')}
                value={name === 'start_month' ? startDate : endDate}
                onChange={newValue => {
                  if (name === 'start_month') {
                    setStartDate(newValue);
                  } else {
                    setEndDate(newValue);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    size="small"
                    helperText={null}
                    sx={{
                      '.css-1l0q85s-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                        color: `${appThemeState?.buttonPrimary} !important`,
                      },
                      '.css-1mlvvws-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                        { borderColor: `${appThemeState?.buttonPrimary} !important`, borderWidth: '2px' },
                    }}
                  />
                )}
                PopperProps={{
                  sx: {
                    '.Mui-selected': { color: 'white', backgroundColor: `${appThemeState?.buttonPrimary} !important` },
                    // '&.css-zug823-MuiTypography-root-PrivatePickersMonth-root.Mui-selected': { color: 'white', backgroundColor: '#7030a0 !important' },
                  },
                }}
              />
            </LocalizationProvider>
          </div>
        ) : (
          <Field name={name} label={title} component={component} options={options} fullWidth />
        )}
      </Grid>
    );
  };

  return (
    <>
      {title.includes('Year') || title.includes('Month') ? null : (
        <Search className="searchbar-styles">
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            value={searchText}
            onChange={(e: any) => handleChange(e)}
            className="input-search-styles"
            placeholder="Search…"
            fullWidth
            style={{ width: '100% !important' }}
            inputProps={{ 'aria-label': 'search', maxlength: '80' }}
            sx={{
              '& .MuiInputBase-input': { width: '100% !important' },
            }}
          />
        </Search>
      )}
      <SimpleBarReact style={{ maxHeight: '220px' }}>
        <Grid
          container
          spacing={2}
          className={
            title.includes('Year') || title.includes('Month')
              ? 'timeline-container-styles'
              : 'accordion-container-styles'
          }
        >
          {fieldSelector(name, title, searchText)}
        </Grid>
      </SimpleBarReact>
    </>
  );
};

export default AccordionContent;
