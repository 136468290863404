import { ACTIONS } from '../../constants/actionConstants';

const filterState: any = {
  productCount: null,
  productValues: {
    department: null,
    subCategory: null,
    classId: null,
  },
  locationCount: null,
  locationValues: null,
  salesOverviewFilter: null,
  forecastFilter: null,
  preSeasonFilter: null,
  inSeasonFilter: null,
  allocationFilter: null,
  customReportFilter: null,
  defaultFilter: null,
};

export const getFilterState = (state = filterState, action: any) => {
  switch (action.type) {
    case ACTIONS.SET_FILTER_STATE:
      return { ...state, ...action.data };
    // case ACTIONS.RESET_SUB_TAB_STATE:
    //   return productFilterState;
    default:
      return state;
  }
};
