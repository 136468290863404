// import { useEffect, useState } from 'react';
// import { ProfileButton } from 'components/ProfileButton';
// import { Grid } from '@mui/material';
// import { useSelector } from 'react-redux';
// import UserProfileIcon from 'images/shikhin-profile-icon.png';
// import EmailIcon from 'images/email-icon.png';
// import { Link } from 'react-router-dom';
// import AccentureIcon from 'images/acc.png';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import AppsIcon from '@mui/icons-material/Apps';
// import './LandingPage.css';
// import { landingPageData } from 'constants/landingPageData';
// import { onLogout } from 'utils/util';
// import { useHistory } from 'react-router-dom';
// import ImageSvg from 'helper/SVGComponent';
// import { dataUrlToImage } from 'utils/util';
// const LandingPage = (props: any) => {
//   const [appLogo, setAppLogo] = useState('');
//   const appThemeState = useSelector((state: any) => state.appTheme);
//   const history = useHistory();
//   const [email, setEmail] = useState('');
//   // let email: any = sessionStorage.userId && JSON.parse(sessionStorage.userId).username;

//   useEffect(() => {
//     let email: any = '';
//     async function getImage(url: any, name: any) {
//       let { image } = await dataUrlToImage(url, name);
//       if (name === 'appLogo') {
//         setAppLogo(image);
//       }
//     }
//     getImage(appThemeState?.appLogo, 'appLogo');
//     getImage(appThemeState?.companyLogo, 'companyLogo');
//     if (sessionStorage.userId && JSON.parse(sessionStorage.userId).username) {
//       email =
//         JSON.parse(sessionStorage.userId).username + '@' + appThemeState?.companyName + '.com';
//       setEmail(email);
//     }
//   }, [appThemeState]);
//   return (
//     <div
//       style={{ background: appThemeState?.appBackground }}
//       className="wrapper d-flex align-items-stretch"
//     >
//       <div className="landing-background">
//         <div className="nav-bar-landing-page">
//           <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
//             <img width={20} src={appLogo} alt="app-logo" />
//             <b style={{ color: 'white', marginLeft: '10px', fontSize: '1.1rem' }}>
//               {appThemeState?.companyName}
//             </b>
//           </div>
//           <div>
//             <AppsIcon style={{ color: 'white', marginRight: '15px', cursor: 'pointer' }} />
//             <AddCircleOutlineIcon
//               style={{ color: 'white', marginRight: '17px', cursor: 'pointer' }}
//             />
//             <ProfileButton
//               userInfo={{
//                 firstName: email,
//               }}
//               menuActions={[
//                 {
//                   handler: () => {
//                     onLogout();
//                     history.push('/login');
//                   },
//                   label: 'logout',
//                 },
//               ]}
//               variant={'basic'}
//             />
//           </div>
//         </div>
//         <div className="landing-page-content">
//           <Grid container spacing={2}>
//             <Grid item md={12}>
//               <h1 style={{ marginLeft: '22.3rem' }} className="landing-page-title">
//                 Supply Chain OS
//               </h1>
//             </Grid>
//             <Grid item md={4}>
//               <div className="landing-page-profile-card">
//                 <div>
//                   <img id="landing-profile-photo" src={UserProfileIcon} alt="profile icon" />
//                 </div>
//                 <h3 className="profile-card-title">Shikhin</h3>
//                 <div style={{ marginBottom: '0.5rem' }}>{email}</div>
//                 <div>
//                   <img width={15} src={appLogo} alt="profile icon" />
//                   <b style={{ marginLeft: '5px' }}>{appThemeState?.companyName}</b>
//                 </div>
//               </div>
//             </Grid>
//             <Grid item md={8}>
//               <div className="landing-page-apps-card">
//                 <Grid container>
//                   {landingPageData.map(data => (
//                     <Grid item md={2} className="landing-app-list-item">
//                       <Link to={data.url} className="landing-page-apps-link">
//                         <img
//                           width="70%"
//                           src={data.icon}
//                           className="landing-menu-icon"
//                           alt="profile icon"
//                         />
//                         <p style={{ fontSize: '0.62rem', fontWeight: '500' }}>{data.name}</p>
//                       </Link>
//                     </Grid>
//                   ))}
//                 </Grid>
//               </div>
//             </Grid>
//           </Grid>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LandingPage;

import { useEffect, useState } from 'react';
import { ProfileButton } from 'components/ProfileButton';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import UserProfileIcon from 'images/shikhin-profile-icon.png';
import EmailIcon from 'images/email-icon.png';
import { Link } from 'react-router-dom';
import AccentureIcon from 'images/acc.png';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AppsIcon from '@mui/icons-material/Apps';
import './LandingPage.css';
import { landingPageData } from 'constants/landingPageData';
import { onLogout } from 'utils/util';
import { useHistory } from 'react-router-dom';
import ImageSvg from 'helper/SVGComponent';
import { dataUrlToImage } from 'utils/util';
import { useMsal } from '@azure/msal-react';


const LandingPage = (props: any) => {
  const [appLogo, setAppLogo] = useState('');
  const appThemeState = useSelector((state: any) => state.appTheme);
  const history = useHistory();
  const [email, setEmail] = useState('');
  // let email: any = sessionStorage.userId && JSON.parse(sessionStorage.userId).username;
  const { instance, accounts } = useMsal();
  const [username,setUsername]=useState('');
  const[name,setName]=useState<string>('');

  const onLogoutClick = () => {
    instance.logoutRedirect();
  };

  useEffect(() => {
     const currentAccount=instance.getActiveAccount();
     if (currentAccount) {
      setUsername(currentAccount.username);
     }
  },[instance]);

  useEffect(() => {
    const currentAccount = instance.getActiveAccount();
    if (currentAccount?.name) {
      setName(currentAccount.name);
    }
  }, [instance]);

  useEffect(() => {
    let email: any = '';
    async function getImage(url: any, name: any) {
      let { image } = await dataUrlToImage(url, name);
      if (name === 'appLogo') {
        setAppLogo(image);
      }
    }
    getImage(appThemeState?.appLogo, 'appLogo');
    getImage(appThemeState?.companyLogo, 'companyLogo');
    // if (sessionStorage.userId && JSON.parse(sessionStorage.userId).username) {
    //   email =
    //     JSON.parse(sessionStorage.userId).username + '@' + appThemeState?.companyName + '.com';
    //   setEmail(email);
    // }
  }, [appThemeState]);
  useEffect(() => {
    document.title = 'Supply Chain OS';
  }, []);
  const handleLinkClick = (newTitle: string) => {
    document.title = newTitle;
  };
  return (
    <div
      style={{ background: appThemeState?.appBackground }}
      className="wrapper d-flex align-items-stretch"
    >
      <div className="landing-background">
        <div className="nav-bar-landing-page">
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <img width={20} src={appLogo} alt="app-logo" />
            <b style={{ color: 'white', marginLeft: '10px', fontSize: '1.1rem' }}>
              {appThemeState?.companyName}
            </b>
          </div>
          <div>
            <AppsIcon style={{ color: 'white', marginRight: '15px', cursor: 'pointer' }} />
            <AddCircleOutlineIcon
              style={{ color: 'white', marginRight: '17px', cursor: 'pointer' }}
            />
            <ProfileButton
              userInfo={{
                // firstName: email,
                firstName: username,
              }}
              menuActions={[
                {
                  // handler: () => {
                  //   onLogout();
                  //   history.push('/login');
                  // },
                  handler: onLogoutClick,
                  label: 'logout',
                },
              ]}
              variant={'basic'}
            />
          </div>
        </div>
        <div className="landing-page-content">
          <Grid container spacing={2}>
            <Grid item md={12}>
              <h1 style={{ marginLeft: '22.3rem' }} className="landing-page-title">
                Supply Chain OS
              </h1>
            </Grid>
            <Grid item md={4}>
              <div className="landing-page-profile-card">
                <div>
                  <img id="landing-profile-photo" src={UserProfileIcon} alt="profile icon" />
                </div>
                <h3 className="profile-card-title">{name}</h3>
                <div style={{ marginBottom: '0.5rem' }}>{username}</div>
                <div>
                  <img width={15} src={appLogo} alt="profile icon" />
                  <b style={{ marginLeft: '5px' }}>{appThemeState?.companyName}</b>
                </div>
              </div>
            </Grid>
            <Grid item md={8}>
              <div className="landing-page-apps-card">
                <Grid container>
                  {landingPageData.map(data => (
                    <Grid item md={2} className="landing-app-list-item">
                      <Link
                        to={data.url}
                        className="landing-page-apps-link"
                        onClick={() => handleLinkClick(data.title)}
                      >
                        <img
                          width="70%"
                          src={data.icon}
                          className="landing-menu-icon"
                          alt="profile icon"
                        />
                        <p style={{ fontSize: '0.62rem', fontWeight: '500' }}>{data.title}</p>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
 
export default LandingPage;
