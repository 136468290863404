import CssBaseline from '@mui/material/CssBaseline';
import { PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material';
import { theme } from 'styles/theme';

export type RootProviderProps = PropsWithChildren<{}>;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
    },
  },
});

export const RootProvider = ({ children }: RootProviderProps) => (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </QueryClientProvider>
);
