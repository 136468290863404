import React from 'react';

import { FilterViewProps } from './FilterView.props';
import Filter from '../../views/Filter/Filter';

const FilterView: React.FC<FilterViewProps> = props => {
  const { onFilterAction, appTheme } = props;
  const onApplyFilter = () => {
    //Todo save filter functionality here
    onFilterAction();
  };
  const onCancelFilter = () => {
    //Todo cancel filter functionality here
    onFilterAction();
  };
  return (
    <div>
      <Filter onApplyFilter={onApplyFilter} onCancelFilter={onCancelFilter} appTheme={appTheme} />
      {/* <div onClick={onApplyFilter}>ApplyFilterBtn</div>
      <div onClick={onCancelFilter}>CancelFilterBtn</div> */}
    </div>
  );
};
export default FilterView;
