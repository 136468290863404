import React, { FunctionComponent, useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import LoginPage from 'views/LoginPage';
import './AuthComponent.css';
 import Cookies from 'js-cookie';


const RequireAuth = (props: any) => {
  const history = useHistory();
  const { Component } = props;
  const userDetails = sessionStorage.getItem('userId') || '';
  // const userDetails = Cookies.get('userId') || '';
  const [state, setState] = useState({
    auth: userDetails,
  });
  useEffect(() => {
    checkAuth();
  }, []);

  const MemoizedComp = useMemo(() => {
    return Component;
  }, [Component]);

  const checkAuth = () => {
    const userData: any = sessionStorage.getItem('userId') || '';
    // const userData: any = Cookies.get('userId') || '';
    const parsedUserData: any = userData ? JSON.parse(userData) : null;
    if (parsedUserData) {
      setState({ auth: JSON.parse(userData) });
    }

    if (!userData) {
      history.push(`/login`);
    }
  };

  return state?.auth ? <MemoizedComp /> : <LoginPage />;
};

export default RequireAuth;


// import React, { FunctionComponent, useMemo, useState, useEffect } from 'react';
// import { useHistory } from 'react-router';
// import LoginPage from 'views/LoginPage';
// import './AuthComponent.css';
// import Cookies from 'js-cookie';


// const RequireAuth = (props: any) => {
//   const history = useHistory();
//   const { Component } = props;
//   // const userDetails = sessionStorage.getItem('userId') || '';
//   const userDetails = Cookies.get('userId') || '';
//   const [state, setState] = useState({
//     auth: userDetails,
//   });
//   useEffect(() => {
//     checkAuth();
//   }, []);

//   const MemoizedComp = useMemo(() => {
//     return Component;
//   }, [Component]);

//   const checkAuth = () => {
//     // const userData: any = sessionStorage.getItem('userId') || '';
//     const userData: any = Cookies.get('userId') || '';
//     const parsedUserData: any = userData ? JSON.parse(userData) : null;
//     if (parsedUserData) {
//       setState({ auth: JSON.parse(userData) });
//     }

//     if (!userData) {
//       history.push(`/login`);
//     }
//   };

//   return state?.auth ? <MemoizedComp /> : <LoginPage />;
// };

// export default RequireAuth;

