import { useQuery } from 'react-query';
import { putTestData } from 'services/apiCalls';

function formatDataForBarChart(data: any[]) {
  const values: any[] = [];
  const ranges: any[] = [];
  if (Array.isArray(data) && data.length) {
    data.forEach((item: any) => {
      ranges.push(item.name);
      values.push(item.value);
    });
  }
  return { values, ranges };
}
export default function useGetContributions(
  class_list: string[] = [],
  start_year: string,
  start_month: string,
  end_year: string = '',
  end_month: string = '',
  store_list: string[] = [],
  dept_list: string[] = [],
  sub_category_list: string[] = []
) {
  const path = `forecast_pre_season/aggregationClassStore`;
  const { data, isLoading } = useQuery(
    [
      'aggregationContributionsClassStore',
      class_list,
      start_year,
      start_month,
      end_year,
      end_month,
      store_list,
      dept_list,
      sub_category_list,
    ],
    () =>
      putTestData(path, {
        class_list,
        start_year,
        start_month,
        end_year,
        end_month,
        store_list,
        dept_list,
        sub_category_list,
      }),
    {
      enabled:
        !!start_year &&
        !!start_month &&
        !!class_list.length &&
        Array.isArray(store_list) &&
        !!store_list.length,
    }
  );
  //fortmat data for bar chart
  const { values, ranges } = formatDataForBarChart(data);
  return {
    getContributionLoader: isLoading,
    values,
    ranges,
    data,
  };
}
