import React, { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { TopNavBarProps } from './TopNavBar.props';
import './TopNavBar.css';
import SubTabs from './SubTabs/SubTabs';
import { SideBarData } from 'constants/SideBarData';
import { setSubTabsState } from 'redux/action/subTabSelections';

import { defaultAllocation, defaultValues, defaultForecast } from 'utils/constant';
import { setFilterState } from 'redux/action/globalFilter';
import FilterTabs from 'components/FilterTabs';

import { ProfileButton } from 'components/ProfileButton';
import MainHeader from './MainHeader/MainHeader';
const TopNavBar: FC<TopNavBarProps> = props => {
  const dispatch = useDispatch();
  const { navs, profileButtonProps, setOpenAlert, isFilterActive } = props;
  const subTabsState = useSelector((state: any) => state.subTabsState);

  const filterState = useSelector((state: any) => state.filterState);
  const { pathname } = useLocation();
  const appThemeState = useSelector((state: any) => state.appTheme);
  useEffect(() => {
    let defaultData: any = null;
    if (pathname.includes('salesOverview')) {
      defaultData = {
        ...defaultForecast,
        grouping_level: 'department',
        category: 'revenue',
        module: 'salesOverviewFilter',
      };
      if (!filterState?.salesOverviewFilter) {
        dispatch(
          setFilterState({
            productCount:
              defaultData?.department.length +
              defaultData?.classId.length +
              defaultData?.subCategory.length,
            productValues: {
              department: defaultData?.department.length,
              subCategory: defaultData?.subCategory.length,
              classId: defaultData?.classId.length,
            },
            locationCount: defaultData?.storeId.length,
            locationValues: defaultData?.storeId.length,
            salesOverviewFilter: { ...defaultData },
          })
        );
      } else {
        dispatch(
          setFilterState({
            productCount:
              filterState?.salesOverviewFilter?.department.length +
              filterState?.salesOverviewFilter?.classId.length +
              filterState?.salesOverviewFilter?.subCategory.length,
            productValues: {
              department: filterState?.salesOverviewFilter?.department.length,
              subCategory: filterState?.salesOverviewFilter?.subCategory.length,
              classId: filterState?.salesOverviewFilter?.classId.length,
            },
            locationCount: filterState?.salesOverviewFilter?.storeId.length,
            locationValues: filterState?.salesOverviewFilter?.storeId.length,
          })
        );
      }
    } else if (pathname.includes('forecast')) {
      defaultData = { ...defaultForecast, module: 'forecastFilter' };
      if (!filterState?.forecastFilter) {
        dispatch(
          setFilterState({
            productCount:
              defaultData?.department.length +
              defaultData?.classId.length +
              defaultData?.subCategory.length,
            productValues: {
              department: defaultData?.department.length,
              subCategory: defaultData?.subCategory.length,
              classId: defaultData?.classId.length,
            },
            locationCount: defaultData?.storeId.length,
            locationValues: defaultData?.storeId.length,
            forecastFilter: { ...defaultData },
          })
        );
      } else {
        dispatch(
          setFilterState({
            productCount:
              filterState?.forecastFilter?.department.length +
              filterState?.forecastFilter?.classId.length +
              filterState?.forecastFilter?.subCategory.length,
            productValues: {
              department: filterState?.forecastFilter?.department.length,
              subCategory: filterState?.forecastFilter?.subCategory.length,
              classId: filterState?.forecastFilter?.classId.length,
            },
            locationCount: filterState?.forecastFilter?.storeId.length,
            locationValues: filterState?.forecastFilter?.storeId.length,
          })
        );
      }
    } else if (pathname.includes('preSeason')) {
      defaultData = {
        ...defaultValues,
        end_month: 'SEP',
        grouping_level: 'department',
        module: 'preSeasonFilter',
      };
      if (!filterState?.preSeasonFilter) {
        dispatch(
          setFilterState({
            productCount:
              defaultData?.department.length +
              defaultData?.classId.length +
              defaultData?.subCategory.length,
            productValues: {
              department: defaultData?.department.length,
              subCategory: defaultData?.subCategory.length,
              classId: defaultData?.classId.length,
            },
            locationCount: defaultData?.storeId.length,
            locationValues: defaultData?.storeId.length,
            preSeasonFilter: { ...defaultData },
          })
        );
      } else {
        dispatch(
          setFilterState({
            productCount:
              filterState?.preSeasonFilter?.department.length +
              filterState?.preSeasonFilter?.classId.length +
              filterState?.preSeasonFilter?.subCategory.length,
            productValues: {
              department: filterState?.preSeasonFilter?.department.length,
              subCategory: filterState?.preSeasonFilter?.subCategory.length,
              classId: filterState?.preSeasonFilter?.classId.length,
            },
            locationCount: filterState?.preSeasonFilter?.storeId.length,
            locationValues: filterState?.preSeasonFilter?.storeId.length,
          })
        );
      }
    } else if (pathname.includes('inSeason')) {
      defaultData = {
        ...defaultValues,
        end_month: 'SEP',
        grouping_level: 'department',
        module: 'inSeasonFilter',
      };
      if (!filterState?.inSeasonFilter) {
        dispatch(
          setFilterState({
            productCount:
              defaultData?.department.length +
              defaultData?.classId.length +
              defaultData?.subCategory.length,
            productValues: {
              department: defaultData?.department.length,
              subCategory: defaultData?.subCategory.length,
              classId: defaultData?.classId.length,
            },
            locationCount: defaultData?.storeId.length,
            locationValues: defaultData?.storeId.length,
            inSeasonFilter: { ...defaultData },
          })
        );
      } else {
        dispatch(
          setFilterState({
            productCount:
              filterState?.inSeasonFilter?.department.length +
              filterState?.inSeasonFilter?.classId.length +
              filterState?.inSeasonFilter?.subCategory.length,
            productValues: {
              department: filterState?.inSeasonFilter?.department.length,
              subCategory: filterState?.inSeasonFilter?.subCategory.length,
              classId: filterState?.inSeasonFilter?.classId.length,
            },
            locationCount: filterState?.inSeasonFilter?.storeId.length,
            locationValues: filterState?.inSeasonFilter?.storeId.length,
          })
        );
      }
    } else if (pathname.includes('allocation')) {
      defaultData = { ...defaultAllocation, module: 'allocationFilter' };
      if (!filterState?.allocationFilter) {
        dispatch(
          setFilterState({
            productCount:
              defaultData?.department.length +
              defaultData?.classId.length +
              defaultData?.subCategory.length,
            productValues: {
              department: defaultData?.department.length,
              subCategory: defaultData?.subCategory.length,
              classId: defaultData?.classId.length,
            },
            locationCount: defaultData?.storeId.length,
            locationValues: defaultData?.storeId.length,
            allocationFilter: { ...defaultData },
          })
        );
      } else {
        dispatch(
          setFilterState({
            productCount:
              filterState?.allocationFilter?.department.length +
              filterState?.allocationFilter?.classId.length +
              filterState?.allocationFilter?.subCategory.length,
            productValues: {
              department: filterState?.allocationFilter?.department.length,
              subCategory: filterState?.allocationFilter?.subCategory.length,
              classId: filterState?.allocationFilter?.classId.length,
            },
            locationCount: filterState?.allocationFilter?.storeId.length,
            locationValues: filterState?.allocationFilter?.storeId.length,
          })
        );
      }
    } else if (pathname.includes('customReport')) {
      defaultData = {
        ...defaultForecast,
        grouping_level: 'department',
        category: 'revenue',
        module: 'customReportFilter',
      };
      if (!filterState?.customReportFilter) {
        dispatch(
          setFilterState({
            productCount:
              defaultData?.department.length +
              defaultData?.classId.length +
              defaultData?.subCategory.length,
            productValues: {
              department: defaultData?.department.length,
              subCategory: defaultData?.subCategory.length,
              classId: defaultData?.classId.length,
            },
            locationCount: defaultData?.storeId.length,
            locationValues: defaultData?.storeId.length,
            customReportFilter: { ...defaultData },
          })
        );
      } else {
        dispatch(
          setFilterState({
            productCount:
              filterState?.customReportFilter?.department.length +
              filterState?.customReportFilter?.classId.length +
              filterState?.customReportFilter?.subCategory.length,
            productValues: {
              department: filterState?.customReportFilter?.department.length,
              subCategory: filterState?.customReportFilter?.subCategory.length,
              classId: filterState?.customReportFilter?.classId.length,
            },
            locationCount: filterState?.customReportFilter?.storeId.length,
            locationValues: filterState?.customReportFilter?.storeId.length,
          })
        );
      }
    } else {
      defaultData = {
        ...defaultForecast,
        grouping_level: 'department',
        category: 'revenue',
        module: 'defaultFilter',
      };
      if (!filterState?.defaultFilter) {
        dispatch(
          setFilterState({
            productCount:
              defaultData?.department.length +
              defaultData?.classId.length +
              defaultData?.subCategory.length,
            productValues: {
              department: defaultData?.department.length,
              subCategory: defaultData?.subCategory.length,
              classId: defaultData?.classId.length,
            },
            locationCount: defaultData?.storeId.length,
            locationValues: defaultData?.storeId.length,
            defaultFilter: { ...defaultData },
          })
        );
      } else {
        dispatch(
          setFilterState({
            productCount:
              filterState?.defaultFilter?.department.length +
              filterState?.defaultFilter?.classId.length +
              filterState?.defaultFilter?.subCategory.length,
            productValues: {
              department: filterState?.defaultFilter?.department.length,
              subCategory: filterState?.defaultFilter?.subCategory.length,
              classId: filterState?.defaultFilter?.classId.length,
            },
            locationCount: filterState?.defaultFilter?.storeId.length,
            locationValues: filterState?.defaultFilter?.storeId.length,
          })
        );
      }
    }
  }, [pathname]);

  const handleFilterSelection = (filter: any) => {
    if (isFilterActive || pathname.includes('createNewReport') || pathname.includes('editReport')) {
      setOpenAlert(true);
    } else {
      let newSelectedSubTabs = subTabsState?.selectedSubTabs;
      let prevSelectedSubTab = subTabsState?.selectedSubTab;
      if (subTabsState?.selectedSubTabs.filter((item: any) => item?.isFilter === true).length) {
        newSelectedSubTabs = subTabsState?.selectedSubTabs.filter(
          (item: any) => item?.isFilter === false
        ); // removes existing filter from subtabs
      }
      dispatch(
        setSubTabsState({
          selectedSubTabs: [...newSelectedSubTabs, filter],
          prevSelectedSubTab: prevSelectedSubTab,
          selectedSubTab: filter,
          filterStatus: true,
        })
      );
    }
  };
  return (
    <div id="content" className="p-4 p-md-3 pt-0">
      {' '}
      <MainHeader profileButtonProps={profileButtonProps} appTheme={appThemeState} />
      <FilterTabs
        appTheme={appThemeState}
        handleFilterSelection={handleFilterSelection}
        SideBarData={SideBarData}
        filterState={filterState}
      />
      <SubTabs setOpenAlert={setOpenAlert} isFilterActive={isFilterActive} />
      {props.children}
    </div>
  );
};

export default TopNavBar;
