import { ACTIONS } from '../../constants/actionConstants';

const subTabsState: any = {
  selectedSubTabs: [],
  selectedSubTab: null,
  prevSelectedSubTab: null,
  filterStatus: false,
};

export const getSubTabsState = (state = subTabsState, action: any) => {
  switch (action.type) {
    case ACTIONS.SET_SUB_TAB_STATE:
      return { ...state, ...action.data };
    case ACTIONS.RESET_SUB_TAB_STATE:
      return subTabsState;
    default:
      return state;
  }
};
