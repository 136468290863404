import { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { ICellRendererParams } from 'ag-grid-community';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import DeployIcon from 'images/deploy-icon.svg';
import { setConfigStatus } from 'redux/action/configurations';
export default function ConfidenceCell(params: ICellRendererParams) {
  const appTheme = useSelector((state: any) => state.appTheme);
  const onDeployConfig = (value: any) => {
    // console.log('test onDeployConfig', value);
  };
  const onGenerateConfig = (value: any) => {
    // console.log('test onGenerateConfig', value);
  };

  return (
    <Grid container spacing={1} alignItems="center" justifyItems={'start'}>
      <Grid item sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        {
          <div
            style={{
              color: 'black',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              fontFamily: 'SFProRoundedRegular',
            }}
          >
            {params?.value?.type === 'generate' && (
              <div>
                <Button
                  onClick={() => onGenerateConfig(params.value)}
                  variant="contained"
                  type="submit"
                  style={{
                    backgroundColor: '#ddd9d9',

                    color: appTheme?.buttonPrimary,
                    fontFamily: 'SFProRoundedRegular',
                    width: '1rem',
                    height: '1.5rem',
                  }}
                >
                  Generate
                </Button>
              </div>
            )}
            {params?.value?.type === 'deploy' && (
              <div>
                <Button
                  onClick={() =>
                    params.value.status === 'completed' && onDeployConfig(params.value)
                  }
                  variant="contained"
                  type="submit"
                  disabled={params.value.status !== 'completed'}
                  style={{
                    backgroundColor: '#ddd9d9',

                    color: params.value.status !== 'completed' ? '' : '#7030a0',
                    fontFamily: 'SFProRoundedRegular',
                    width: '1rem',
                    height: '1.5rem',
                  }}
                >
                  Deploy
                </Button>
              </div>
            )}
          </div>
        }
      </Grid>
    </Grid>
  );
}
