import { ACTIONS } from '../../constants/actionConstants';

export const setCustomModuleStatus = (data: any) => {
  return {
    type: ACTIONS.SET_CUSTOM_MODULE_STATUS,
    data,
  };
};

export const resetCustomModuleStatus = (data: any) => {
  return {
    type: ACTIONS.RESET_CUSTOM_MODULE_STATUS,
    data,
  };
};
