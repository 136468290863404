const val = 'REACT_APP';

export class Configuration {
  proc = process.env ?? {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  win = (window as any).exp_env ?? {};

  static isDebug(): boolean {
    return (process.env ?? {})['REACT_APP_DEBUG']?.toUpperCase() === 'TRUE';
  }

  static isLocal(): boolean {
    return (process.env ?? {})['REACT_APP_LOCAL']?.toUpperCase() === 'TRUE';
  }

  GetConfig(key: string): string | undefined {
    const fullkey = `${val}_${key.toUpperCase()}`;
    const local = Configuration.isLocal();

    if (!local && this.win[fullkey]) {
      return this.win[fullkey] as string;
    }

    if (this.proc[fullkey]) {
      return this.proc[fullkey] as string;
    }

    return undefined;
  }
}
