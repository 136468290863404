import { Grid, Button } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from 'images/edit-icon.svg';
import DeleteIcon from 'images/trash-sharp-icon.svg';
import { useEffect } from 'react';
import { useDeleteModule, useGetModules } from 'services';
import { setCustomModuleStatus } from 'redux/action/customModule';
import Loader from 'components/Loader/Loader';
import { CircularProgress } from '@mui/material';
export default function ConfidenceCell(params: ICellRendererParams) {
  const dispatch = useDispatch();
  const {
    mutate: mutateDelete,
    isLoading: isLoadingDelete,
    isSuccess: isSuccessDelete,
    data: dataUpdate,
  } = useDeleteModule();
  const appTheme = useSelector((state: any) => state.appTheme);
  const { refetch: modulesRefetch } = useGetModules();
  const onEditModule = (value: any) => {
    dispatch(setCustomModuleStatus({ moduleToEdit: value }));
  };
  const onDeleteModule = (value: any) => {
    if (value && value?.module_name) {
      let moduleToDelete = {
        moduleName: value?.module_name,
      };
      mutateDelete(moduleToDelete);
    }
  };
  async function refetchModulesApi() {
    modulesRefetch();
  }
  useEffect(() => {
    if (isSuccessDelete) {
      refetchModulesApi();
    }
  }, [isSuccessDelete]);
  return (
    <Grid container spacing={1} alignItems="center" justifyItems={'start'}>
      <Grid item sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        {
          <div
            style={{
              color: 'black',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              fontFamily: 'SFProRoundedRegular',
              cursor: 'pointer',
            }}
          >
            {params?.value?.type === 'edit' && (
              <div
                onClick={() => {
                  onEditModule(params.value);
                }}
              >
                <img
                  style={{ width: '15px', color: '#ffff' }}
                  className={'config-edit-icon'}
                  src={EditIcon}
                  alt="icon"
                />
              </div>
            )}
            {params?.value?.type === 'delete' && (
              <>
                {isLoadingDelete ? (
                  <CircularProgress
                    color="secondary"
                    style={{
                      color: appTheme?.buttonPrimary,
                      width: '1.7rem',
                      height: '1.7rem',
                      marginTop: '0.5rem',
                    }}
                  />
                ) : (
                  <div
                    onClick={() => {
                      onDeleteModule(params.value);
                    }}
                  >
                    <img
                      style={{ width: '15px', color: '#ffff' }}
                      className={'config-edit-icon'}
                      src={DeleteIcon}
                      alt="icon"
                    />
                  </div>
                )}
              </>
            )}
          </div>
        }
      </Grid>
    </Grid>
  );
}
