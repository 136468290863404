import { AxiosInstance, AxiosRequestConfig } from 'axios';

const attachToken = (config: AxiosRequestConfig) => {
  return config;
};

const REQUEST_INTERCEPTORS = [attachToken];

export default function registerRequestInterceptors(api: AxiosInstance) {
  REQUEST_INTERCEPTORS.forEach(interceptor => api.interceptors.request.use(interceptor));
}
