export const productGrainData = [
  'product_level_0',
  'product_level_1',
  'product_level_2',
  'product_level_3',
  'product_level_4',
];

export const locationGrainData = ['store', 'region', 'state'];

export const forecastConfigStatus = {
  new: 'Active',
  inprogress: 'In-Progress',
  completed: 'Completed',
  error: 'Error',
};
