import { useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from 'images/edit-icon.svg';
import DisabledEditIcon from 'images/disabled-edit-icon.svg';
import DeleteIcon from 'images/trash-sharp-icon.svg';
import DisabledDeleteIcon from 'images/trash-disabled-icon.svg';
import { useDeleteAppTheme, useGetAppThemeConfigurations, useApplyAppTheme } from 'services';
import { setAppThemeToUpdate } from 'redux/action/appTheme';
import Loader from 'components/Loader/Loader';
import { CircularProgress } from '@mui/material';
import './AppThemeConfidenceCell.css';
export default function ConfidenceCell(params: ICellRendererParams) {
  const dispatch = useDispatch();
  const {
    mutate: mutateDelete,
    isLoading: isLoadingDelete,
    isSuccess: isSuccessDelete,
  } = useDeleteAppTheme();

  const {
    mutate: mutateApply,
    isLoading: isLoadingApply,
    isSuccess: isSuccessApply,
  } = useApplyAppTheme();
  const { refetchAppTheme } = useGetAppThemeConfigurations();
  const appTheme = useSelector((state: any) => state.appTheme);

  const onEditModule = (value: any) => {
    dispatch(setAppThemeToUpdate({ appThemeToEdit: value?.app_theme_name }));
  };
  const onDeleteAppTheme = (value: any) => {
    if (value && value?.app_theme_name) {
      let appThemeToDelete = {
        AppThemeName: value?.app_theme_name,
      };
      mutateDelete(appThemeToDelete);
    }
  };
  const onApplyAppTheme = (value: any) => {
    if (value && value?.app_theme_name) {
      let appThemeToApply = {
        AppthemeName: value?.app_theme_name,
        truestatus: true,
        falsestatus: false,
      };
      mutateApply(appThemeToApply);
    }
  };

  async function refetchApi() {
    refetchAppTheme();
  }
  useEffect(() => {
    if (isSuccessDelete || isSuccessApply) {
      refetchApi();
    }
  }, [isSuccessDelete, isSuccessApply]);
  return (
    <Grid container spacing={1} alignItems="center" justifyItems={'start'}>
      <Grid item sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        {
          <div
            style={{
              color: 'black',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              fontFamily: 'SFProRoundedRegular',
              cursor: params?.value?.app_theme_name === 'Default App Theme' ? 'default' : 'pointer',
            }}
          >
            {params?.value?.type === 'edit' && (
              <div
                onClick={() => {
                  onEditModule(params.value);
                }}
              >
                <img
                  style={{ width: '15px', color: '#ffff' }}
                  src={
                    params?.value?.app_theme_name === 'Default App Theme'
                      ? DisabledEditIcon
                      : EditIcon
                  }
                  alt="icon"
                />
              </div>
            )}
            {params?.value?.type === 'delete' && (
              <>
                {isLoadingDelete ? (
                  <CircularProgress
                    color="secondary"
                    style={{
                      color: appTheme?.buttonPrimary,
                      width: '1.7rem',
                      height: '1.7rem',
                      marginTop: '0.5rem',
                    }}
                  />
                ) : (
                  <div
                    onClick={() => {
                      onDeleteAppTheme(params.value);
                    }}
                  >
                    <img
                      style={{ width: '15px', color: '#ffff' }}
                      className={'config-edit-icon'}
                      src={
                        params?.value?.app_theme_name === 'Default App Theme'
                          ? DisabledDeleteIcon
                          : DeleteIcon
                      }
                      alt="icon"
                    />
                  </div>
                )}
              </>
            )}
            {params?.value?.type === 'apply' && (
              <>
                {isLoadingApply ? (
                  <CircularProgress
                    color="secondary"
                    style={{
                      color: appTheme?.buttonPrimary,
                      width: '1.7rem',
                      height: '1.7rem',
                      marginTop: '0.5rem',
                    }}
                  />
                ) : (
                  <div>
                    <Button
                      onClick={() => {
                        onApplyAppTheme(params.value);
                      }}
                      variant="contained"
                      type="submit"
                      style={{
                        backgroundColor: '#ddd9d9',

                        color: appTheme?.buttonPrimary,
                        fontFamily: 'SFProRoundedRegular',
                        width: '1rem',
                        height: '1.5rem',
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        }
      </Grid>
    </Grid>
  );
}
