import { useQuery, useMutation, QueryClient, QueryCache } from 'react-query';
import { putTestData, callApi } from 'services/apiCalls';
import { customReportsData, columnDataTypesMapping } from 'constants/customReportsData';
import { formatReports } from 'utils/util';
interface newReport {
  reportName: string;
  config: string;
  user: string | 'admin';
}
function useCreateCustomReport() {
  const path = `/custom_report/createCustomReport`;
  const { data, mutate, isLoading, isError, isSuccess } = useMutation({
    mutationFn: (payload: newReport) => {
      const reportData: newReport = {
        ...payload,
        config: payload?.config,
        user: 'admin',
      };
      return putTestData(path, reportData);
    },
    onSuccess: async data => {},
    onError: async () => {},
  });

  return {
    isError,
    isSuccess,
    data,
    mutate,
    isLoading,
  };
}

function useGetCustomReports(isCreateReportLoading: boolean, isDeleteReportSuccess: boolean) {
  const path = `/custom_report/getJsonConfig`;
  const { data, isLoading, isSuccess, refetch, isRefetching } = useQuery(
    ['getJsonConfig', isCreateReportLoading, isDeleteReportSuccess],

    () => putTestData(path, {})
  );
  const formattedReports = formatReports(data);
  return {
    customReports: formattedReports,
    isLoading,
    customReportsSuccess: isSuccess,
    refetch,
    fetchStatus: isRefetching,
  };
}

function useGetChartColumns() {
  let chartColumnsData: any[] = [];
  const path = `/custom_report/getcolumnnames`;
  const { data, isLoading } = useQuery('getChartColumns', () =>
    putTestData(path, { table_name: 'output_fact_data_forecast_and_inventory_optimization' })
  );
  chartColumnsData = data;
  return { chartColumnsData, isLoading };
}
function useGetCustomReportChartData(payload: any, isUpdateSuccess: boolean, chartType: any) {
  const path = `/custom_report/getAggregatedData`;
  const table_name = 'output_fact_data_forecast_and_inventory_optimization';
  let requestPayload = {
    date_range: payload?.dateRange,
    measure_agg: payload?.aggregrations,
    table_name: table_name,
    attribute_filter: payload?.filters,
    groupby_columns: payload?.groupByColns,
    prev_flag: chartType?.type === 5 ? true : false,
  };

  const { data, isLoading, isSuccess, refetch } = useQuery(
    ['getAggregratedData', payload, isUpdateSuccess],
    () => putTestData(path, requestPayload),
    {
      enabled: !!payload,
    }
  );
  return { customReportChartData: data, isLoading, isSuccess, refetch };
}

interface updateReport {
  reportName: string;
  config: string;
}
function useUpdateCustomReport() {
  const path = `custom_report/updateCustomReport`;
  const { data, mutate, isLoading, isError, isSuccess } = useMutation({
    mutationFn: (payload: updateReport) => {
      const reportData: updateReport = {
        ...payload,
        config: payload?.config,
      };
      return putTestData(path, reportData);
    },
    onSuccess: async data => {},
    onError: async () => {},
  });

  return {
    isError,
    isSuccess,
    data,
    mutate,
    isLoading,
  };
}

interface deleteReport {
  reportName: string;
}
function useDeleteCustomReport() {
  const path = `/custom_report/deleteCustomReport`;
  const { data, mutate, isLoading, isError, isSuccess } = useMutation({
    mutationFn: (payload: deleteReport) => {
      const reportData: deleteReport = {
        ...payload,
        reportName: payload?.reportName,
      };
      return putTestData(path, reportData);
    },
    onSuccess: async data => {},
    onError: async () => {},
  });

  return {
    isError,
    isSuccess,
    data,
    mutate,
    isLoading,
  };
}

export {
  useCreateCustomReport,
  useGetCustomReports,
  useGetChartColumns,
  useGetCustomReportChartData,
  useDeleteCustomReport,
  useUpdateCustomReport,
};
