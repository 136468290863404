import { ACTIONS } from '../../constants/actionConstants';

const configStatusState: any = {
  forecastConfigToEdit: null,
};

export const getConfigStatus = (state = configStatusState, action: any) => {
  switch (action.type) {
    case ACTIONS.SET_CONFIG_STATUS:
      return { ...state, ...action.data };
    case ACTIONS.RESET_CONFIG_STATUS:
      return configStatusState;
    default:
      return state;
  }
};
