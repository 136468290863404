import { ACTIONS } from '../../constants/actionConstants';

export const setAppTheme = (data: any) => {
  return {
    type: ACTIONS.SET_APP_THEME,
    data,
  };
};
export const setAppThemeToUpdate = (data: any) => {
  return {
    type: ACTIONS.SET_APP_THEME_UPDATE,
    data,
  };
};

export const resetConfigStatus = (data: any) => {
  return {
    type: ACTIONS.RESET_APP_THEME,
    data,
  };
};
