import {
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getError, getFieldId } from '../field-helper';
import { OptionsFieldProps } from '../form-field.props';
import ClearIcon from '@mui/icons-material/Clear';
import { customStyle } from './MultiSelectField.styles';
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 400,
    },
  },
};

const MultiSelectField: FC<OptionsFieldProps> = ({
  field,
  form,
  label,
  options,
  fullWidth,
  required,
  disabled,
  notifyChange,
  helperText,
  error: propError,
  ...props
}: OptionsFieldProps) => {
  const { name, value } = field;
  const { setFieldTouched, setFieldValue } = form;
  const appThemeState = useSelector((state: any) => state.appTheme);
  const classes = customStyle(appThemeState);
  const { error, hasError } = getError(field, form);
  const fieldId = getFieldId(name);
  const labelId = `${fieldId}-outlined-label`;

  const isAllSelected = options.length > 0 && value.length === options.length;

  const handleChange = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setFieldTouched(name, true);
      setFieldValue(
        name,
        value.length === options.length ? [] : Array.from(options, x => x.value),
        true
      );
      return;
    }
    setFieldTouched(name, true);
    setFieldValue(name, value, true);
  };
  const handleClearClick = (field: any) => {
    setFieldValue(name, [], true);
  };

  return (
    <FormControl
      variant="outlined"
      error={!!propError || hasError}
      size="small"
      margin="none"
      fullWidth={fullWidth}
    >
      <InputLabel id={labelId} required={required} className={classes.inputLabel}>
        {label}
      </InputLabel>

      <Select
        labelId="mutiple-select-label"
        multiple
        value={value}
        onChange={handleChange}
        label={label}
        renderValue={selected => selected.join(', ')}
        MenuProps={MenuProps}
        sx={{
          '& .MuiSelect-iconOutlined': { display: value && value.length === 0 ? '' : 'none' },
          '&.Mui-focused .MuiIconButton-root': { color: appThemeState?.buttonPrimary },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: appThemeState?.buttonSecondary,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: appThemeState?.buttonPrimary,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: appThemeState?.buttonPrimary,
          },
          // '&  .MuiFormLabel-root .MuiInputLabel-root': {
          //   color: chartColorTheme.buttonPrimary,
          // },
          '.MuiSvgIcon-root ': {
            fill: `${appThemeState?.buttonSecondary} !important`,
            fontSize: 'small',
          },
          fontSize: '0.95rem', // font size of selected value
          fontFamily: 'SFProRoundedRegular !important',
        }}
        endAdornment={
          <IconButton
            sx={{ visibility: value && value.length === 0 ? 'hidden' : 'visible' }}
            onClick={() => handleClearClick(field)}
          >
            <ClearIcon />
          </IconButton>
        }
      >
        <MenuItem value="all">
          <ListItemIcon>
            <Checkbox
              checked={isAllSelected}
              indeterminate={value?.length > 0 && value?.length < options.length}
              classes={{
                root: classes.root,
                checked: classes.checked,
              }}
              sx={{
                '.MuiSvgIcon-root ': {
                  fill: '#8c51b8 !important',
                },
              }}
            />
          </ListItemIcon>
          <ListItemText primary="Select All" classes={{ primary: classes.listItemText }} />
        </MenuItem>
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            <ListItemIcon>
              <Checkbox
                disableRipple
                checked={value.indexOf(option.value) > -1}
                classes={{
                  root: classes.root,
                  checked: classes.checked,
                }}
              />
            </ListItemIcon>
            <ListItemText primary={option.label} classes={{ primary: classes.listItemText }} />
          </MenuItem>
        ))}
      </Select>
      {(!!propError || error) && <FormHelperText>{helperText || error}</FormHelperText>}
    </FormControl>
  );
};

export default MultiSelectField;

// sx={{
//   '& .MuiSelect-iconOutlined': { display: value && value.length === 0 ? '' : 'none' },
//   '&.Mui-focused .MuiIconButton-root': { color: 'primary.main' },
//   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//     borderColor: 'red',
//   },
//   fontSize: '1rem',
// }}
