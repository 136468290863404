import NavCategoryIcon from 'images/nav-icon-category.svg';
import NavStoreIcon from 'images/nav-icon-store.svg';
import NavWeekIcon from 'images/nav-icon-week.svg';
export const FilterData = [
  {
    label: 'Product Filter',
    navs: [
      {
        title: 'Product',
        icon: NavCategoryIcon,
        subNavs: [
          {
            title: 'Department',
            name: 'department',
          },
          {
            title: 'Sub-category',
            name: 'subCategory',
          },
          {
            title: 'Class',
            name: 'classId',
          },
        ],
      },
    ],
  },
  {
    label: 'Timeline Filter',
    navs: [
      {
        title: 'Timeline',
        icon: NavWeekIcon,
        subNavs: [
          {
            title: 'From Month/Year',
            name: 'start_month',
          },
          // {
          //   title: 'From Year',
          //   name: 'start_year',
          // },
          {
            title: 'To Month/Year',
            name: 'end_month',
          },
          // {
          //   title: 'To Year',
          //   name: 'end_year',
          // },
        ],
      },
    ],
  },
  {
    label: 'Location Filter',
    navs: [
      {
        title: 'Location',
        icon: NavStoreIcon,
        subNavs: [
          {
            title: 'Store Id',
            name: 'storeId',
          },
        ],
      },
    ],
  },
];