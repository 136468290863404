import { useQuery, useMutation, QueryClient, QueryCache } from 'react-query';
import { putTestData, callApi } from 'services/apiCalls';
import { dataUrlToImage, formatAppThemeConfigs } from 'utils/util';
interface deleteAppTheme {
  AppThemeName: string;
}
function useCreateAppThemeConfigurations() {
  const path = `/custom_module/createCustomAppTheme`;
  const { data, mutate, isLoading, isError, isSuccess } = useMutation({
    mutationFn: (payload: any) => {
      const appThemeData: any = {
        ...payload,
      };
      return putTestData(path, appThemeData);
    },
    onSuccess: async data => {},
    onError: async () => {},
  });

  return {
    isError,
    isSuccess,
    data,
    mutate,
    isLoading,
  };
}

function useGetAppThemeConfigurations() {
  const path = `/custom_module/getAppTheme`;
  const { data, isLoading, isSuccess, refetch, isRefetching } = useQuery(
    ['getAppThemeConfigReport'],

    () => putTestData(path)
  );

  const { columnDefs, tableRows, activeAppTheme }: any = formatAppThemeConfigs(data);

  return {
    columnDefs,
    tableRows,
    activeAppTheme,
    appThemeData: data,
    isLoading,
    getAppThemeSuccess: isSuccess,
    refetchAppTheme: refetch,
    refetchAppThemeStatus: isRefetching,
  };
}
function useDeleteAppTheme() {
  const path = `/custom_module/deleteAppThemeData`;
  const { data, mutate, isLoading, isError, isSuccess } = useMutation({
    mutationFn: (payload: deleteAppTheme) => {
      const moduleData: deleteAppTheme = {
        ...payload,
      };
      return putTestData(path, moduleData);
    },
    onSuccess: async data => {},
    onError: async () => {},
  });
  return {
    isError,
    isSuccess,
    data,
    mutate,
    isLoading,
  };
}
function useApplyAppTheme() {
  console.log('useApplyAppTheme');
  const path = `/custom_module/applythememodule`;
  const { data, mutate, isLoading, isError, isSuccess } = useMutation({
    mutationFn: (payload: any) => {
      const moduleData: any = {
        ...payload,
      };
      console.log('useApplyAppTheme', payload);
      return putTestData(path, moduleData);
    },
    onSuccess: async data => {},
    onError: async () => {},
  });
  return {
    isError,
    isSuccess,
    data,
    mutate,
    isLoading,
  };
}

function useUpdateAppThemeConfigurations() {
  const path = `/custom_module/updateCustomAppTheme`;
  const { data, mutate, isLoading, isError, isSuccess } = useMutation({
    mutationFn: (payload: any) => {
      const appTheme: any = {
        ...payload,
      };
      return putTestData(path, appTheme);
    },
    onSuccess: async data => {},
    onError: async () => {},
  });

  return {
    isError,
    isSuccess,
    data,
    mutate,
    isLoading,
  };
}
export {
  useGetAppThemeConfigurations,
  useCreateAppThemeConfigurations,
  useDeleteAppTheme,
  useApplyAppTheme,
  useUpdateAppThemeConfigurations,
};
