import {
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Grid,
} from '@mui/material';
import React, { FC, useEffect } from 'react';
import { getError, getFieldId } from '../field-helper';
import { OptionsFieldProps } from '../form-field.props';
import ClearIcon from '@mui/icons-material/Clear';
import { customStyle } from './CheckboxField.styles';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setFilterState } from 'redux/action/globalFilter';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 400,
    },
  },
};

const MultiCheckboxField: FC<OptionsFieldProps> = ({
  field,
  form,
  label,
  options,
  fullWidth,
  required,
  disabled,
  notifyChange,
  helperText,
  error: propError,
  ...props
}: OptionsFieldProps) => {
  const { name, value } = field;
  const { setFieldTouched, setFieldValue } = form;
  const appThemeState = useSelector((state: any) => state.appTheme);
  const classes = customStyle(appThemeState);
  const { error, hasError } = getError(field, form);
  const fieldId = getFieldId(name);
  const labelId = `${fieldId}-outlined-label`;

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const filterState = useSelector((state: any) => state.filterState);

  const isAllSelected = options.length > 0 && value.length === options.length;

  const handleChange = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setFieldTouched(name, true);
      setFieldValue(
        name,
        value.length === options.length ? [] : Array.from(options, x => x.value),
        true
      );
      return;
    }
    setFieldTouched(name, true);
    setFieldValue(name, value, true);
  };
  const handleClearClick = (field: any) => {
    setFieldValue(name, [], true);
  };

  useEffect(() => {
    if (name === 'department') {
      dispatch(
        setFilterState({
          productCount: filterState.productCount,
          productValues: {
            department: value?.length,
            subCategory: filterState.productValues.subCategory,
            classId: filterState.productValues.classId,
          },
        })
      );
    }
  }, [value]);

  useEffect(() => {
    if (name === 'subCategory') {
      dispatch(
        setFilterState({
          productCount: filterState.productCount,
          productValues: {
            department: filterState.productValues.department,
            subCategory: value?.length,
            classId: filterState.productValues.classId,
          },
        })
      );
    }
  }, [value]);

  useEffect(() => {
    if (name === 'classId') {
      dispatch(
        setFilterState({
          productCount: filterState.productCount,
          productValues: {
            department: filterState.productValues.department,
            subCategory: filterState.productValues.subCategory,
            classId: value?.length,
          },
        })
      );
    }
  }, [value]);

  useEffect(() => {
    if (name === 'storeId') {
      dispatch(
        setFilterState({
          locationCount: filterState.locationCount,
          locationValues: value?.length,
        })
      );
    }
  }, [value]);

  const handleClick = (val: any) => {
    let newValue: any;
    if (value.includes(val)) {
      newValue = value?.filter((item: any) => item !== val);
    } else {
      newValue = [...value, val];
    }
    setFieldValue(name, newValue, true);
  };

  return (
    <FormControl
      variant="outlined"
      error={!!propError || hasError}
      size="small"
      margin="none"
      fullWidth={fullWidth}
    >
      <Grid container className="contentGrid">
        {value?.map((val: any, index: any) => (
          <Grid item md={3} style={{ marginBottom: '1.5rem' }} onClick={() => handleClick(val)}>
            <MenuItem key={val} value={val}>
              <ListItemIcon>
                <Checkbox
                  disableRipple
                  checked={value.indexOf(val) > -1}
                  classes={{
                    root: classes.root,
                    checked: classes.checked,
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={val} classes={{ primary: classes.listItemText }} />
            </MenuItem>
          </Grid>
        ))}
      </Grid>
      <Grid container className="contentGrid">
        {options.map(option => (
          <>
            {value.includes(option.value) ? null : (
              <Grid
                item
                md={3}
                style={{ marginBottom: '1rem' }}
                onClick={() => handleClick(option.value)}
              >
                <MenuItem key={option.value} value={option.value}>
                  <ListItemIcon>
                    <Checkbox
                      disableRipple
                      checked={value.indexOf(option.value) > -1}
                      classes={{
                        root: classes.root,
                        checked: classes.checked,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={option.label}
                    classes={{ primary: classes.listItemText }}
                  />
                </MenuItem>
              </Grid>
            )}
          </>
        ))}
      </Grid>
      {/* </Select> */}
      {(!!propError || error) && <FormHelperText>{helperText || error}</FormHelperText>}
    </FormControl>
  );
};

export default MultiCheckboxField;
