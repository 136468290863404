import React, { FC, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { SubTabsProps } from './SubTabs.props';
import SubTab from './SubTab/SubTab';
import './SubTabs.css';
import { setSubTabsState } from 'redux/action/subTabSelections';
import SubTabsCarousel from 'components/SubTabsCarousel/SubTabsCarousel';
import { useLocation } from 'react-router-dom';
const SubTabs: FC<SubTabsProps> = props => {
  const { setOpenAlert, isFilterActive } = props;
  const dispatch = useDispatch();
  const subTabsState = useSelector((state: any) => state.subTabsState);
  const appThemeState = useSelector((state: any) => state.appTheme);
  const { pathname } = useLocation();

  const handleSelection = (subTab: any) => {
    if (isFilterActive || pathname.includes('createNewReport') || pathname.includes('editReport')) {
      setOpenAlert(true);
    } else {
      dispatch(setSubTabsState({ selectedSubTab: subTab }));
    }
  };
  const onsubTabClose = (closedSubTab: any) => {
    if (
      (isFilterActive || pathname.includes('createNewReport') || pathname.includes('editReport')) &&
      closedSubTab?.isFilter
    ) {
      setOpenAlert(true);
    } else {
      const newsubTabs = subTabsState?.selectedSubTabs?.filter(
        (subTab: any) => subTab?.key !== closedSubTab?.key
      );
      let newSlectedSubTabIndex = newsubTabs?.length - 1;
      const newSelectedSubTab = newsubTabs?.[newSlectedSubTabIndex]
        ? newsubTabs?.[newSlectedSubTabIndex]
        : null;
      dispatch(setSubTabsState({ selectedSubTabs: newsubTabs }));
      dispatch(
        setSubTabsState({
          selectedSubTab: newSelectedSubTab,
        })
      );
    }
  };

  return (
    <>
      <div className={`sub-tab-container`}>
        {subTabsState?.selectedSubTabs?.length > 3 ? (
          <div className="sub-tab-item-container">
            <SubTabsCarousel show={4} subTabLength={subTabsState?.selectedSubTabs?.length || 0}>
              {subTabsState?.selectedSubTabs && subTabsState?.selectedSubTabs?.length
                ? subTabsState?.selectedSubTabs.map((subTab: any) => (
                    <SubTab
                      isSlide={subTabsState?.selectedSubTabs?.length > 3}
                      subTabData={subTab}
                      handleSelection={handleSelection}
                      selectedSubTab={subTabsState?.selectedSubTab}
                      handleclose={onsubTabClose}
                      appTheme={appThemeState}
                    />
                  ))
                : []}
            </SubTabsCarousel>
          </div>
        ) : (
          <div className="sub-tab-item-container">
            {subTabsState?.selectedSubTabs && subTabsState?.selectedSubTabs?.length
              ? subTabsState?.selectedSubTabs.map((subTab: any) => (
                  <SubTab
                    isSlide={subTabsState?.selectedSubTabs?.length > 3}
                    subTabData={subTab}
                    handleSelection={handleSelection}
                    selectedSubTab={subTabsState?.selectedSubTab}
                    handleclose={onsubTabClose}
                    appTheme={appThemeState}
                  />
                ))
              : []}
          </div>
        )}
      </div>
    </>
  );
};

export default SubTabs;
