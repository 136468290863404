import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { AlertDialogProps } from './AlertDialog.props';

const useStyles = makeStyles({
  content: ({ contentWidth }: AlertDialogProps) => ({
    width: contentWidth ?? 'auto',
  }),
});

const AlertDialog: FC<AlertDialogProps> = (props: AlertDialogProps) => {
  const {
    title = 'Alert',
    confirmButtonLabel = 'Ok',
    message,
    onConfirm,
    open,
    onDelete,
    deleteButtonLabel,
    appTheme,
  } = props;
  const classes = useStyles(props);
  return (
    <Dialog open={open}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent className={classes.content}>{message}</DialogContent>
      <DialogActions>
        <Button
          size="small"
          color="primary"
          style={{
            backgroundColor: appTheme?.buttonPrimary,
            border: `1px solid ${appTheme?.buttonPrimary}`,
          }}
          variant="contained"
          onClick={onConfirm}
        >
          {confirmButtonLabel}
        </Button>
        {deleteButtonLabel ? (
          <Button
            size="small"
            color="primary"
            style={{
              backgroundColor: appTheme?.buttonPrimary,
              border: `1px solid ${appTheme?.buttonPrimary}`,
            }}
            variant="contained"
            onClick={onDelete}
          >
            {deleteButtonLabel}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
