import { useQuery } from 'react-query';
import { putTestData } from 'services/apiCalls';

export default function useGetSalesInventoryData(
  start_year: string,
  start_month: string,
  end_year: string = '',
  end_month: string = '',
  class_list: string[] = [],
  store_list: string[] = []
) {
  const path = `sales_and_inventory_performance/salesInventoryTimeline`;
  const { data, isLoading } = useQuery(
    [
      'salesInventoryTimeline',
      class_list,
      start_year,
      start_month,
      end_year,
      end_month,
      store_list,
    ],
    () =>
      putTestData(path, {
        class_list,
        start_year,
        start_month,
        end_year,
        end_month,
        store_list,
      }),
    {
      enabled:
        !!start_year &&
        !!start_month &&
        !!class_list.length &&
        Array.isArray(store_list) &&
        !!store_list.length,
    }
  );

  const month: any[] = [];
  const total_allocated_inventory: any[] = [];
  const Actual_Inventory: any[] = [];
  const forecastSales: any[] = [];
  const actualSales: any[] = [];
  const targetSales: any[] = [];
  const targetInventory: any[] = [];
  const forecastRevenue: any[] = [];
  const targetRevenue: any[] = [];
  const grossMargin: any[] = [];
  const sellThrough: any[] = [];
  const targetGrossMargin: any[] = [];
  const targetSellThrough: any[] = [];
  if (Array.isArray(data) && data.length) {
    data.forEach((item: any) => {
      month.push(`${item.month}-${item.year}`);
      total_allocated_inventory.push(item.Total_Allocated_Inventory);
      targetInventory.push(item.Target_Inventory);
      forecastSales.push(item.Forecast_Sales);
      actualSales.push(item.Actual_Sales);
      forecastRevenue.push(item.Forecast_Revenue);
      targetRevenue.push(item.Target_Revenue);
      grossMargin.push(item.Gross_Margin);
      sellThrough.push(item.sell_through);
      targetGrossMargin.push(item.Target_Gross_Margin);
      targetSellThrough.push(item.Target_sell_through);
      targetSales.push(item.Target_Sales);
      Actual_Inventory.push(item.Actual_Inventory);
    });
  }

  return {
    month,
    forecastSales,
    actualSales,
    targetSales,
    targetGrossMargin,
    grossMargin,
    Actual_Inventory,
    total_allocated_inventory,
    targetInventory,
    sellThrough,
    targetSellThrough,
    isLoading,
  };
}
