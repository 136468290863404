import React, { FC, useEffect, useState } from 'react';
import { ProfileButton } from 'components/ProfileButton';
import PieChartIcon from 'images/top-nav-pie-chart.svg';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import { dataUrlToImage } from 'utils/util';
import './MainHeader.css';
const MainHeader = (props: any) => {
  const { profileButtonProps, appTheme } = props;
  const [appLogo, setAppLogo] = useState<any>(null);
  useEffect(() => {
    async function getImage(url: any, name: any) {
      let { image } = await dataUrlToImage(url, name);
      setAppLogo(image);
    }
    getImage(appTheme?.appLogo, 'appLogo');
  }, [appTheme]);

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light ${appTheme?.appBackground} navbar-full-width`}
    >
      <div className="container-fluid">
        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <img width={20} src={appLogo} alt="app logo" />
          <b style={{ color: 'white', marginLeft: '10px', fontSize: '1.1rem' }}>
            {appTheme?.companyName}
          </b>
        </div>
        <div id="navbarSupportedContent">
          <img className="icon" src={PieChartIcon} width="24" height={24} alt="search-icon" />
          <span style={{ marginRight: '6rem' }} className="title sf-pro-display-heavy">
            Demand Planning
          </span>
        </div>
        <Link to={'/home'}>
          <HomeIcon className="topnav-icon-style" />
        </Link>
        <ProfileButton {...profileButtonProps} appTheme={appTheme} />
      </div>
    </nav>
  );
};

export default MainHeader;
