import LoadingAnimation from 'images/loading-animated.svg'
const LoadingApp = (props: any) => {
  return (
    <div
      className="wrapper d-flex align-items-stretch"
    >
      <div className="landing-background">
        <div className="landing-page-content">
          <img style={{ width: '12rem' }} src={LoadingAnimation} alt='loading' />
        </div>
      </div>
    </div>
  );
};

export default LoadingApp;
