// import AppLayout from 'components/AppLayout';
// import './App.css';
// function App() {
//   return <AppLayout />;
// }

// export default App;



import AppLayout from 'components/AppLayout';
import './App.css';
import {  MsalProvider } from '@azure/msal-react';
import { AuthenticatedTemplate } from '@azure/msal-react'
import { IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
 
const msalConfig = {
  auth: {
    clientId: '32debaf1-dd17-4860-b658-fa0e7bf62342',
    authority: `https://login.microsoftonline.com/30af61e6-f207-4ecc-97ac-2932bc0503dc`,
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage', // or 'sessionStorage'
    storeAuthStateInCookie: false,
  },
};
 
const pca = new PublicClientApplication(msalConfig);
type AppProps = {
  pca: IPublicClientApplication;
};
 
function App({ pca }: AppProps) {
  return (
    <MsalProvider instance={pca}>
     <AuthenticatedTemplate>
    <AppLayout />
    </AuthenticatedTemplate>
    </MsalProvider>
  );
 
}
 
export default App;