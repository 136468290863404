import { useQuery } from 'react-query';
import { putTestData } from 'services/apiCalls';

export default function useStoreAnalysis(
  class_list: string[],
  start_year: string,
  start_month: string,
  end_year: string = '',
  end_month: string = '',
  store_list: string[] = [],
  dept_list: string[] = [],
  sub_category_list: string[] = []
) {
  const path = `first_allocation/aggregationStoreLevel`;
  const { data, isLoading } = useQuery(
    [
      'aggregationStoreLevel',
      class_list,
      start_year,
      start_month,
      end_year,
      end_month,
      store_list,
      dept_list,
      sub_category_list,
    ],
    () =>
      putTestData(path, {
        class_list,
        start_year,
        start_month,
        end_year,
        end_month,
        store_list,
        dept_list,
        sub_category_list,
      }),
    {
      enabled:
        !!start_year &&
        !!start_month &&
        !!class_list.length &&
        Array.isArray(store_list) &&
        !!store_list.length,
    }
  );
  const stores: any[] = [];
  const forcastSales: any[] = [];
  const salesPlan: any[] = [];
  const totalAllocatedInventory: any[] = [];
  const averagePreviousSeasonSellThrough: any[] = [];
  const averageSellThrough: any[] = [];
  const historical_average_inventory: any[] = [];
  if (Array.isArray(data) && data.length) {
    data.forEach((item: any) => {
      stores.push(item.STORE_ID);
      forcastSales.push(item.Forecast_Sales);
      salesPlan.push(item.SalesPlan);
      totalAllocatedInventory.push(item.Total_Allocated_Inventory);
      averagePreviousSeasonSellThrough.push(item.Previous_Season_sell_through);
      averageSellThrough.push(item.sell_through);
      historical_average_inventory.push(item.Historical_Average_Inventory);
    });
  }
  return {
    stores,
    forcastSales,
    salesPlan,
    totalAllocatedInventory,
    averagePreviousSeasonSellThrough,
    averageSellThrough,
    historical_average_inventory,
    salesLoader: isLoading,
  };
}
