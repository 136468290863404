import { useQuery } from 'react-query';
import { putTestData } from 'services/apiCalls';

export default function useAggregationClassLevel(
  class_list: string[] = [],
  start_year: string,
  start_month: string,
  end_year: string = '',
  end_month: string = '',
  store_list: string[] = [],
  dept_list: string[] = [],
  sub_category_list: string[] = []
) {
  const path = `first_allocation/aggregationClassLevel`;
  const { data, isLoading } = useQuery(
    [
      'aggregationClassLevel',
      class_list,
      start_year,
      start_month,
      end_year,
      end_month,
      store_list,
      dept_list,
      sub_category_list,
    ],
    () =>
      putTestData(path, {
        class_list,
        start_year,
        start_month,
        end_year,
        end_month,
        store_list,
        dept_list,
        sub_category_list,
      }),
    {
      enabled:
        !!start_year &&
        !!start_month &&
        !!class_list.length &&
        Array.isArray(store_list) &&
        !!store_list.length,
    }
  );
  const month: any[] = [];
  const total_allocated_inventory: any[] = [];
  const forecastSales: any[] = [];
  const actualSales: any[] = [];
  const totalInventory: any[] = [];
  if (Array.isArray(data) && data.length) {
    data.forEach((item: any) => {
      month.push(`${item.month}-${item.year}`);
      total_allocated_inventory.push(item.Total_Allocated_Inventory);
      totalInventory.push(item.Total_Inventory);
      forecastSales.push(item.Forecast_Sales);
      actualSales.push(item.Actual_Sales);
    });
  }
  return {
    month,
    totalInventory,
    forecastSales,
    actualSales,
    total_allocated_inventory,
    isLoading,
  };
}
