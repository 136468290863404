import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { SubTabProps } from './SubTab.props';
import CloseIcon from 'images/close-circle.svg';
import './SubTab.css';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';

const SubTab: FC<SubTabProps> = props => {
  const {
    subTabData,
    handleSelection,
    selectedSubTab,
    handleclose,
    isSlide = false,
    appTheme,
  } = props;

  const maxLengthString = (str: string) => {
    return str.substring(0, Math.min(str.length, 24));
  };

  const formatTitle = (subTabTitle: string) => {
    if (subTabTitle?.includes('>')) {
      const title = subTabTitle.split(' > ')[1];
      if (title.length > 24) {
        return maxLengthString(title) + '...';
      } else {
        return title;
      }
    } else {
      return subTabTitle;
    }
  };

  const TooltipStyles = withStyles({
    tooltip: {
      fontFamily: 'SFProRoundedRegular',
    },
  })(Tooltip);

  return (
    <div
      className={`sub-tab-item `}
      key={subTabData?.key}
      onClick={() => {
        handleSelection(subTabData);
      }}
    >
      <TooltipStyles title={subTabData?.subTabTitle} arrow>
        <div
          className={`subtab ${isSlide ? 'slide-width' : ''} ${
            selectedSubTab?.key === subTabData?.key ? 'active-subtab' : ''
          }`}
          style={{
            borderTop:
              selectedSubTab?.key === subTabData?.key ? `5px solid ${appTheme?.buttonPrimary}` : '',
          }}
        >
          <div className="subtab-label sf-pro-rounded-regular">
            {formatTitle(subTabData?.subTabTitle)}
          </div>
          <img
            id="sub-tab-close"
            src={CloseIcon}
            onClick={e => {
              handleclose(subTabData);
              e.stopPropagation();
            }}
            width={22}
            height={22}
            alt="close-icon"
          />
        </div>
      </TooltipStyles>
    </div>
  );
};

export default SubTab;
