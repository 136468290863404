import React, { FC, useEffect, useState } from 'react';
import SimpleBarReact from 'simplebar-react';
import { SideBarProps } from './SideBar.props';
import NavSearchIcon from 'images/nav-icon-search.svg';
import NavIconToggle from 'images/nav-icon-toggle.svg';
import SideBarMenu from './SideBarMenu/SideBarMenu';
import './SideBar.css';

const SideBar: FC<SideBarProps> = props => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav id="sidebar" className={`${!isOpen ? 'active' : ''}`}>
      <SimpleBarReact className="simpleBarHeight">
        <div className={` p-3 pt-1 ${!isOpen ? 'active-padding-left' : 'inactive-padding'}`}>
          {/* toggle icon */}
          <div className="nav-items-container">
            <ul
              className={`list-unstyled components mb-4 nav-item-ul ${
                isOpen ? 'toggle-open-style' : ''
              }`}
            >
              <li className="toggle-li">
                <div data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                  <img
                    id="sidebarCollapse"
                    className="nav-toggle-icon"
                    src={NavIconToggle}
                    onClick={() => {
                      handleToggle();
                    }}
                    width="20"
                    height={20}
                    alt="search-icon"
                  />
                </div>
              </li>
            </ul>
          </div>

          {<SideBarMenu isOpen={isOpen} setIsOpen={handleToggle} {...props} />}
        </div>
      </SimpleBarReact>
    </nav>
  );
};

export default SideBar;
